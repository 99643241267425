import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import { ApiDelete, ApiPost, ApiPut, ApiUpload } from "../../../helpers/API/ApiData";
import { ImSpinner9 } from "react-icons/im";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { MdCancel } from "react-icons/md";
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import loader from "../../../media/loaderSkyline.gif";

export default function SubAdmin() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [error, setError] = useState({});
  const [flag, setFlag] = useState(false);
  const [fLoading, setFLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showImage, setShowImage] = useState("");
  const [newData, setNewData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const userType = 1;

  const columns = [
    {
      dataField: "Name",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center" role="button">
            <div className="symbol symbol-50px symbol-light mr-4">
              <img src={row?.profile_image} className="img-fluid object-cover" loading="lazy" alt="" />
            </div>
            <div>
              <a className="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                {row?.firstName || (!row?.firstName && !row?.lastName && "-")} {row?.lastName || ""}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "email",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{(cell && moment(cell).format("DD-MM-YYYY HH:mm A")) || "-"}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div style={{ width: "80px" }}>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
              onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
              </span>
            </a>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
              </span>
            </a>
          </div>
        );
      },
    },
  ];

  const validate = (values) => {
    const err = {};
    if (!values.firstName) {
      err.firstName = "First name is required";
    }
    if (!values.lastName) {
      err.lastName = "Last name is required";
    }
    if (!values.email) {
      err.email = "Email address is required";
    }
    setError(err);
    if (Object.keys(err).length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "firstName" || name === "lastName" || name === "password") {
      if (name !== "password" && value && !value.match(/^[A-Za-z]+$/)) {
        return;
      }
    }
    setNewData({ ...newData, [name]: value.trimStart() });
  };

  const changeImage = (e) => {
    if (!e.target.files[0]) {
      return;
    }
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    setShowImage(fileURL);
  };
  console.log("showImageEngineer", showImage);

  const editBtnClick = (row) => {
    setIsEdit(true);
    setError({});
    setModal(true);
    setShowImage(row?.profile_image);
    setNewData({
      id: row?._id,
      firstName: row?.firstName,
      lastName: row?.lastName,
      email: row?.email,
    });
  };

  const onDelete = async (Id) => {
    await ApiDelete(`/subadmin/${Id}`)
      .then(() => {
        Swal.fire({
          text: "Engineer Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };

  const onUpdate = async () => {
    if (validate(newData)) {
      setFlag(true);
      return;
    } else {
      setFlag(false);
    }
    setFLoading(true);
    let body = {
      id: newData.id,
      firstName: newData?.firstName.trim(),
      lastName: newData?.lastName.trim(),
      password: newData?.password,
      email: newData?.email || null,
    };
    if (document.getElementById("image").files[0]) {
      const newImage = await imageChange();
      console.log(newImage,"aaaaaa")
      body["profile_image"] = newImage;
    }
    else{
      body["profile_image"] = showImage?showImage:"";

    }
    // console.log(document.getElementById("image").files[0],"bbbbbb")
    // console.log(showImage,"cccccc")

    // if (!showImage) {
    //   body["profile_image"] = showImage;

    // }
    await ApiPut("/subadmin", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, searching);
        setFLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFLoading(false);
      });
  };

  const handleSubmit = async () => {
    console.log("Submitting with newData:", newData);
    if (validate(newData)) {
      setFlag(true);
      return;
    } else {
      setFlag(false);
    }
    setFLoading(true);
    let body = {
      firstName: newData?.firstName.trim(),
      lastName: newData?.lastName.trim(),
      email: newData?.email || null,
      password: newData?.password || null,
      userType: userType,
    };
    if (document.getElementById("image").files[0]) {
      const newImage = await imageChange();
      body["profile_image"] = newImage;
    }
    await ApiPost("/subadmin", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, searching);
        setFLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFLoading(false);
      });
  };

  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };

  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };

  const imageChange = async (e) => {
    let imageDom = document.getElementById("image");
    if (!imageDom.files[0]) {
      return;
    }
    let file = imageDom.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    console.log("fileasd", file);

    let formData = new FormData();
    formData.append("image", file);
    let returnImg = "";
    await ApiUpload("admin/image", formData)
      .then((res) => {
        // setNewData({ ...newData, image: res?.data?.data });
        returnImg = res?.data?.data;
      })
      .catch((err) => console.log("res_blob", err));
    return returnImg;
  };

  const handlesearch = (e) => {
    setcurrentpage(1);
    setsearching(e.target.value);
    fetchData(1, pagesize, e.target.value);
  };

  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    setFetching(true);
    await ApiPost("/getsubadmin", body)
      .then((res) => {
        console.log("/getsubadmin", res?.data?.data);
        setData(res?.data?.data?.sub_admin_data);
        settotalpage(res?.data?.data?.state?.page_limit);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Sub Admin</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              Sub Admin
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div className="card card-custom">
          <div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div className="card-title ">
              {/* <h3 className="card-label">Video Playlist</h3> */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Search by Name and Email</Tooltip>}
              >
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control bg-light"
                    name="searchText"
                    placeholder="Search by..."
                    value={searching}
                    onChange={(e) => handlesearch(e)}
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </OverlayTrigger>
            </div>

            <div className="card-toolbar">
              <button
                className="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setFlag(false);
                  setError({});
                  setModal(true);
                  setShowImage("");
                  setNewData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                  });
                }}
              >
                Add Sub Admin
              </button>
            </div>
          </div>

          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              noDataIndication={() => {
                if (fetching) {
                  return (
                    <div className="text-center">
                      <img src={loader} className="h-100px" alt="loadingLogo" />
                    </div>
                  );
                } else {
                  return <NoDataTable />;
                }
              }}
            />

            <div className="d-flex justify-content-between pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>

              <div className="my-2">
                <div className="d-flex align-items-center pagination-drpdown">
                  <select
                    className="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add New"} Sub Admin</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-6">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                label="firstName"
                id="firstName"
                required
                name="firstName"
                maxLength="20"
                onChange={handleChange}
                value={newData?.firstName}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.firstName && <span className="errorInput">{error["firstName"]}</span>}
            </Form.Group>

            <Form.Group className="col-md-6">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                label="lastName"
                id="lastName"
                required
                name="lastName"
                maxLength="20"
                onChange={handleChange}
                value={newData?.lastName}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.lastName && <span className="errorInput">{error["lastName"]}</span>}
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Address"
                id="email1"
                className="form-control"
                required
                name="email"
                onChange={handleChange}
                value={newData?.email}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
                autoComplete="off"
              />
              {/* {error?.email &&
								<span className="errorInput">
									{error["email"]}
								</span>} */}
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Password"
                id="password"
                className="form-control"
                required
                name="password"
                onChange={handleChange}
                value={newData?.password}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
                autoComplete="off"
              />
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label className="w-100">Image</Form.Label>
              <input
                name="image"
                type="file"
                id="image"
                className="form-control"
                onChange={changeImage}
                hidden={
                  showImage &&
                  !showImage.includes("1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg")
                }
              />
              {showImage &&
                !showImage.includes("1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg") && (
                  <div className="symbol symbol-75 position-relative mt-1 ml-5">
                    <img src={showImage} className="img-fluid object-cover" />
                    <span
                      className="imageRemovoIconSpan"
                      onClick={() => {
                        setShowImage("");
                        document.getElementById("image").value = null;
                      }}
                    >
                      <MdCancel color="red" fontSize={20} />
                    </span>
                  </div>
                )}
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn-color" onClick={onUpdate} disabled={fLoading}>
              Update Changes {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
            </button>
          ) : (
            <button className="btn btn-color" onClick={handleSubmit} disabled={fLoading}>
              Submit {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
