/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import firebase from "../../../../../app/firebase";
import { ApiPost } from "../../../../../helpers/API/ApiData";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const [divicetoken, setdivicetoken] = useState("");
  const [type, setType] = useState();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open ` : "";
  };
  const signout = async () => {
    let body = {
      deviceToken: divicetoken,
    };
    await ApiPost("/logout", body).then((res) => {
      console.log(res);
      // handleClose1()
      // window.location.reload()
      // history.push("/appointmentlist");
      // toast.success(res.data.message);
      // window.location.reload()
    });
    localStorage.clear();
    window.location.href = "/";
  };
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      console.log("ffffffff");
      // messaging.onMessage((data)=>{
      //   console.log("=======",data)
      // })
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          console.log("Token :", token);
          setdivicetoken(token);
        })
        .catch(() => {
          console.log("error");
        });
    }
  }, []);
  useEffect(() => {
    const Id = JSON.parse(localStorage.getItem("userinfo"));
    console.log(Id);
    setType(Id?.response?.userType);
  }, []);
  return (
    <>
      {type == 0 ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/subadmin", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/subadmin">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
              </span>
              <span className="menu-text">Sub Admin</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/client", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/client">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
              </span>
              <span className="menu-text">Client</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/engineer", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/engineer">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Engineer</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/typeMachine", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/typeMachine">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
              </span>
              <span className="menu-text">Machine Type</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/machine", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/machine">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")} />
              </span>
              <span className="menu-text">Machine</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/issueType", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/issueType">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} />
              </span>
              <span className="menu-text">Issue Type</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/areaCode", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/areaCode">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
              </span>
              <span className="menu-text">Area Code</span>
            </NavLink>
          </li>
          {/* <li className={`menu-item ${getMenuItemActive("/sparePart", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/sparePart">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
              </span>
              <span className="menu-text">Spare Part</span>
            </NavLink>
          </li> */}
          <li className={`menu-item ${getMenuItemActive("/complaint", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/complaint">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
              </span>
              <span className="menu-text">Complaint</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/alert", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/alert">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
              </span>
              <span className="menu-text">Alert</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/map", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/map">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")} />
              </span>
              <span className="menu-text">Map</span>
            </NavLink>
          </li>
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive("/error", true)}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")} />
              </span>
              <span className="menu-text">Trash</span>
              <i className="menu-arrow mt-2" />
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Trash</span>
                  </span>
                </li>
                <li className={`menu-item ${getMenuItemActive("/trash-client")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/trash-client">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Client</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/trash-engineer")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/trash-engineer">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Engineer</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/trash-machine")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/trash-machine">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Machine</span>
                  </NavLink>
                </li>
                <li className={`menu-item ${getMenuItemActive("/trash-complaint")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/trash-complaint">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">Complaint</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          <li className={`menu-item ${getMenuItemActive("", false)}`} aria-haspopup="true">
            <div className="menu-link" onClick={() => signout()}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")} />
              </span>
              <span className="menu-text">Sign Out</span>
            </div>
          </li>
        </ul>
      ) : null}

      {type == 1 ? (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/* <li className={`menu-item ${getMenuItemActive("/client", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/client">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">Client</span>
          </NavLink>
        </li> */}
          <li className={`menu-item ${getMenuItemActive("/engineer", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/engineer">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
              </span>
              <span className="menu-text">Engineer</span>
            </NavLink>
          </li>
          {/* <li className={`menu-item ${getMenuItemActive("/typeMachine", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/typeMachine">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
            </span>
            <span className="menu-text">Machine Type</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/machine", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/machine">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")} />
            </span>
            <span className="menu-text">Machine</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/subadmin", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/subadmin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">Sub Admin</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/issueType", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/issueType">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} />
            </span>
            <span className="menu-text">Issue Type</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/areaCode", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/areaCode">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
            </span>
            <span className="menu-text">Area Code</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/sparePart", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/sparePart">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker2.svg")} />
            </span>
            <span className="menu-text">Spare Part</span>
          </NavLink>
        </li> */}
          <li className={`menu-item ${getMenuItemActive("/complaint", false)}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/complaint">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
              </span>
              <span className="menu-text">Complaint</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/alert", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/alert">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
            </span>
            <span className="menu-text">Alert</span>
          </NavLink>
        </li>
          {/* <li className={`menu-item ${getMenuItemActive("/alert", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/alert">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
            </span>
            <span className="menu-text">Alert</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/map", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/map">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Map/Marker1.svg")} />
            </span>
            <span className="menu-text">Map</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive("/error", true)}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Trash.svg")} />
            </span>
            <span className="menu-text">Trash</span>
            <i className="menu-arrow mt-2" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Trash</span>
                </span>
              </li>
              <li className={`menu-item ${getMenuItemActive("/trash-client")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/trash-client">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Client</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/trash-engineer")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/trash-engineer">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Engineer</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/trash-machine")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/trash-machine">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Machine</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/trash-complaint")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/trash-complaint">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Complaint</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}

          <li className={`menu-item ${getMenuItemActive("", false)}`} aria-haspopup="true">
            <div className="menu-link" onClick={() => signout()}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")} />
              </span>
              <span className="menu-text">Sign Out</span>
            </div>
          </li>
        </ul>
      ) : null}
    </>
  );
}
