import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useParams } from "react-router-dom";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import loader from "../../../media/loaderSkyline.gif";
import NoDataTable from "../../../common/noDataTable";
import { Form, Modal } from "react-bootstrap";
import Pagination from "@material-ui/lab/Pagination";
import { ImSpinner9 } from "react-icons/im";
import { AiFillEye } from "react-icons/ai";
import ClientMachines from "./ClientMachines";

export default function ClientDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [toggle, setToggle] = useState("personal");
  const [data, setData] = useState({});
  const [complaintsData, setComplaintsData] = useState({});
  const [newData, setNewData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [fLoading, setFLoading] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [assignComp, setAssignComp] = useState([]);
  const [notEngData, setNotEngData] = useState([]);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [countData, setCountData] = useState(null);

  console.log('fetching :>> ', fetching);
  const complaintColumns = [
    {
      dataField: "complaintNo",
      text: "complaint Number",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || ""}</div>;
      },
    },
    {
      dataField: "issueType",
      text: "issue",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-truncate" style={{ width: "120px" }}>
            {row?.issueType[0]?.name || "-"}
          </div>
        );
      },
    },
    {
      dataField: "description",
      text: "description",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-truncate" style={{ width: "120px" }}>
            {cell || "-"}
          </div>
        );
      },
    },
    {
      dataField: "machine_Data",
      text: "machine Number",
      sort: true,
      formatter: (cell, row) => {
        if (cell?.length !== 0) {
          return <div>{cell[0]?.machineNo || "-"}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "machine_Data",
      text: "machine serial number",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length !== 0) {
          return <div>{cell[0]?.serialNumber || "-"}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "createdAt",
      text: "Complatient Date",
      sort: true,
      formatter: (cell, row) => {
        return <div className="text-nowrap">{cell ? moment(cell).format("DD-MM-YYYY hh:mm:ss A") : "-"}</div>;
      },
    },
    {
      dataField: "assignTime",
      text: "Assign Time",
      sort: true,
      formatter: (cell, row) => {
        return <div className="text-nowrap">{cell ? moment(cell).format("DD-MM-YY h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "startTime",
      text: "Running Time",
      sort: true,
      formatter: (cell, row) => {
        return <div className="text-nowrap">{cell ? moment(cell).format("DD-MM-YY h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "endTime",
      text: "Completed Time",
      sort: true,
      formatter: (cell, row) => {
        return <div className="text-nowrap">{cell ? moment(cell).format("DD-MM-YY h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "eng_Data",
      text: "Engineer",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length !== 0) {
          return (
            <div>
              {cell[0]?.firstName || "-"} {cell[0]?.lastName || ""}
            </div>
          );
        } else if (row?.status === 3) {
          return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
            By Admin
          </span>
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "status",
      text: "status",
      sort: true,
      formatter: (cell, row) => {
        if (cell === 0) {
          return (
            <span className="label font-weight-bold label-lg label-light-warning label-inline" role="button" onClick={() => handleStatus(row)}>
              Pending
            </span>
          );
        }
        if (cell === 1) {
          return (
            <span className="label font-weight-bold label-lg label-light-success label-inline" role="button" onClick={() => handleStatus(row)}>
              Assigned
            </span>
          );
        }
        if (cell === 2) {
          return (
            <span className="label font-weight-bold label-lg label-light-info label-inline">
              Running
            </span>
          );
        }
        if (cell === 3) {
          return (
            <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
              Completed
            </span>
          );
        }
      },
    },
    {
      dataField: "view",
      text: "view",
      sort: true,
      formatter: (cell, row) => {
        return (<>
          <a
            title="View User"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => history.push("/complaint-details/" + row?._id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
              <AiFillEye className="" />
            </span>
          </a>
        </>)
      }
    }
  ];
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewData({ ...newData, [name]: value });
  };
  const handleSelectEng = async (e) => {
    if (e.target.value === "") {
      setAssignComp([]);
      return;
    }
    await ApiGet("/complaint/" + e.target.value)
      .then((res) => {
        setAssignComp(res?.data?.data);
      })
      .catch((err) => {
        ErrorToast(err?.message);
      });
  };
  const handleStatus = (row) => {
    setStatusModal(true);
    setNewData({
      id: row?._id,
      engId: "",
    });
    setAssignComp([]);
  };
  const submitAssign = async () => {
    // if (newData?.engId) {
    // 	setError({})
    // } else {
    // 	setError({ engId: "engineer required!" })
    // 	return;
    // }
    setFLoading(true);
    let body = {
      id: newData?.id,
      engId: newData?.engId,
      status: 1,
    };
    await ApiPut("/complaint", body)
      .then((res) => {
        SuccessToast("Complaint Assign Successfully !!!");
        fetchData();
        setStatusModal(false);
        setFLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFLoading(false);
      });
  };
  const getNotAssignEngineer = async () => {
    await ApiGet("/assignEng")
      .then(res => { setNotEngData(res?.data?.data) })
      .catch(err => ErrorToast(err?.message))
  }
  const fetchData = async () => {
    setFetching(true);
    await ApiGet("/Client/" + id)
      .then((res) => {
        console.log("Client/Id", res?.data?.data[0]);
        setData(res?.data?.data[0]);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };
  useEffect(() => {
    getNotAssignEngineer();
    fetchData();
  }, []);

  const fetchComplaintsData = async () => {
    setFetching(true);
    let body = {
      clientId: id,
      page: currentpage,
      limit: Number(pagesize)
    }
    await ApiPost("/complaints", body)
      .then((res) => {
        console.log("complaints/Id", res?.data?.data?.response);
        setComplaintsData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };
  useEffect(() => {
    fetchComplaintsData();
  }, [pagesize, currentpage]);

  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    // fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    // fetchData(i, pagesize, searching);
  };

  const handleCountData = async () => {
    setFetching(true);
    await ApiGet("/clientCount/" + id)
      .then((res) => {
        setCountData(res?.data?.data);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };
  useEffect(() => {
    handleCountData()
  }, []);
  console.log('CountData :>> ', countData);

  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Client Details</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span
              role="button"
              onClick={() => history.push("/client")}
              className="text-hover-info text-muted"
            >
              {" "}
              Clients
            </span>{" "}
            -{" "}
            <span role="button" className="text-muted">
              {" "}
              Client details
            </span>
          </div>
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-lg-4 card mb-5 mb-xl-10 shadow">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap mb-3">
              <div className="me-7 mb-4">
                <div className="">
                  <img
                    className="object-cover rounded"
                    height="160px"
                    width="160px"
                    src={
                      data?.image ||
                      "https://res.cloudinary.com/deh7sqhvn/image/upload/v1675313330/1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg"
                    }
                    alt=""
                  />
                </div>
              </div>
              <div>
                <div className="mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-2">
                      <a className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1 text-capitalize">
                        {data?.firstName || "-"} {data?.lastName || ""}
                      </a>
                    </div>
                    <div className="d-flex flex-wrap fw-bold fs-6 pe-2">
                      <a className="d-flex align-items-center text-gray-600 text-hover-primary mb-2">
                        {data?.email || "-"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex overflow-auto h-55px">
              <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                <li class="nav-item" onClick={() => setToggle("personal")}>
                  <a
                    class={`nav-link text-active-primary me-6 ${toggle === "personal" && "active"}`}
                    data-toggle="tab"
                  >
                    Personal Info.
                  </a>
                </li>
                <li class="nav-item" onClick={() => setToggle("machines")}>
                  <a
                    class={`nav-link text-active-primary me-6 ${toggle === "machines" && "active"}`}
                    data-toggle="tab"
                  >
                    Machine
                  </a>
                </li>
                <li class="nav-item" onClick={() => setToggle("complaints")}>
                  <a
                    class={`nav-link text-active-primary me-6 ${toggle === "complaints" && "active"}`}
                    data-toggle="tab"
                  >
                    Complaints
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body p-4">
              <h2>Machine Statistics</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(205, 239, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>AMC</div>
                        <div className="h1">{countData?.AMC}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(214, 247, 215)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Warranty</div>
                        <div className="h1">{countData?.Warranty}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(239, 236, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Expire</div>
                        <div className="h1">{countData?.Expire}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(239, 236, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>TotalMachine</div>
                        <div className="h1">{countData?.totalMachine}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card">
            <div className="card-body p-4">
              <h2>Complaint Reports</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(255, 244, 203)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Pending</div>
                        <div className="h1">{countData?.PendingComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(205, 239, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Assigned</div>
                        <div className="h1">{countData?.AssignComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(239, 236, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Running</div>
                        <div className="h1">{countData?.RunningComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(214, 247, 215)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Completed</div>
                        <div className="h1">{countData?.CompletedComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body pt-3">
          {toggle === "personal" ? (
            <div className="" id="">
              <div className="card-header cursor-pointer mx-0 px-0">
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Client Personal Details</h3>
                </div>
              </div>
              <div className="card-body pt-9 pb-0 mx-0 px-0">
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">Name</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {data?.firstName || "-"} {data?.lastName || ""}
                    </span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">Email</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">{data?.email || "-"}</span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">Phone No.</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">{data?.phoneNumber || "-"}</span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">Address</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">{data?.address || ""}</span>
                  </div>
                </div>
                <div className="row mb-7">
                  <label className="col-lg-4 fw-bold text-muted">Area Code</label>
                  <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800">
                      {data?.areacode || "-"}
                      {/* {data?.areaCode.map((v) => {
												console.log("v", v);

												// return v?.code
											})} */}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Company Name</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{data?.companyName || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : toggle === "machines" ? (
            <ClientMachines data={data?.machine_data || []} />
          ) : toggle === "complaints" ? (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                headerWrapperClasses="border-0"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="_id"
                data={complaintsData || []}
                columns={complaintColumns}
                noDataIndication={() => {
                  if (fetching) {
                    return (
                      <div className="text-center">
                        <img src={loader} className="h-100px" alt="loadingLogo" />
                      </div>
                    );
                  } else {
                    return <NoDataTable />;
                  }
                }}
              />
              <div className="d-flex justify-content-between  pt-10">
                <div className="my-2">
                  <Pagination
                    count={totalpage}
                    page={currentpage}
                    onChange={onPaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination_"
                  />
                </div>
                <div className="my-2">
                  <div className="d-flex align-items-center pagination-drpdown">
                    <select
                      className="form-control pagination-drpdown1 dropdownPage"
                      id="kt_datatable_search_status"
                      onChange={(e) => handleonchnagespagination(e)}
                      value={pagesize}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </>

          ) : (
            ""
          )}
        </div>
      </div>
      <Modal show={statusModal} onHide={() => setStatusModal(false)} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>Assign Engineer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label></Form.Label>
              <select
                className="form-select"
                placeholder="Enter First Name"
                label="engId"
                id="engId"
                required
                name="engId"
                onChange={(e) => {
                  handleChange(e);
                  handleSelectEng(e);
                }}
                value={newData?.engId}
                role="button"
              >
                <option value="" disabled>
                  Select Engineer
                </option>
                {notEngData?.map((v, i) => (
                  <option value={v?._id} key={i}>
                    {v?.firstName} {v?.lastName}
                  </option>
                ))}
              </select>
            </Form.Group>
            {assignComp.length !== 0 && (
              <div className="px-3">
                <h3 className="text-center mb-3">Assigned Complaints</h3>
                <table className="w-100 table-bordered table">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white fs-16px">Complaint No</th>
                      <th className="text-center text-white fs-16px">Complaint Date</th>
                      <th className="text-center text-white fs-16px">Machine No</th>
                    </tr>
                  </thead>
                  <tbody className="bg-primary-o-30">
                    {assignComp?.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-center">{v?.complaintNo}</td>
                          <td className="text-center">
                            {moment(v?.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td className="text-center">{v?.machine_Data[0]?.machineNo}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-color" onClick={submitAssign}>
            Assign {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
