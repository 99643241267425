import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import firebase from "../../firebase";
import Select from 'react-select';
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ImSpinner9 } from "react-icons/im";
import moment from "moment";

import {
  Dropdown,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import loader from "../../../media/loaderSkyline.gif";
import { AiFillEye } from "react-icons/ai";
import { toast } from "react-hot-toast";
import { MdSwapHoriz } from "react-icons/md";

export default function Complaint() {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [engData, setEngData] = useState([]);
  const [allEngData, setAllEngData] = useState([]);
  const [allClientData, setAllClientData] = useState([]);
  const [assignComp, setAssignComp] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [issueData, setIssueData] = useState([]);
  const [machineDataValue, setMachineDataValue] = useState("");
  const [issueDataValue, setIssueDataValue] = useState("");
  const [discription, setDiscription] = useState("");
  const [machineDataClientValue, setMachineDataClientValue] = useState({});
  const [fLoading, setFLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [videoModal, setVideoModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [error, setError] = useState({});
  const [flag, setFlag] = useState(false);
  const [playStatus, setPlayStatus] = useState({
    name: "Filter Status",
    number: null,
  });
  const [playWarranty, setPlayWarranty] = useState({
    name: "Filter Warranty",
    bool: null,
  });
  const [playMachineStatus, setPlayMachineStatus] = useState(null);
  const [playClientId, setPlayClientId] = useState({
    name: "Filter By Client",
    id: "",
  });
  const [playEngId, setPlayEngId] = useState({
    name: "Filter By Engineer",
    id: "",
  });
  const [playStartDate, setPlayStartDate] = useState(null);
  const [playEndDate, setPlayEndDate] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsClient, setOptionsClient] = useState([]);
  const [optionsEng, setOptionsEng] = useState([]);

  const [newData, setNewData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    id: "",
    engId: "",
  });

  const columns = [
    {
      dataField: "complaintNo",
      text: "complaint No",
      sort: true,
      formatter: (cell, row) => {
        console.log("rowwww", row);
        return <div>{cell || ""}</div>;
      },
    },
    {
      dataField: "client_data",
      text: "client name",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length !== 0) {
          return (
            <div>
              {cell[0]?.firstName ||
                (!cell[0]?.firstName && !cell[0]?.lastName && "N/A")}{" "}
              {cell[0]?.lastName || ""}
            </div>
          );
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "issueType",
      text: "issue",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-truncate" style={{ width: "120px" }}>
            {row?.issueType[0]?.name || "-"}
          </div>
        );
      },
    },
    {
      dataField: "description",
      text: "description",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-truncate" style={{ width: "120px" }}>
            {cell || "-"}
          </div>
        );
      },
    },
    {
      dataField: "machine_Data",
      text: "machine No",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length !== 0) {
          return <div>{cell[0]?.machineNo || "-"}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "machine_Data",
      text: "machine serial no",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length !== 0) {
          return <div>{cell[0]?.serialNumber || "-"}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "code",
      text: "Area Code",
      sort: true,
      formatter: (cell, row) => {
        if (cell?.length !== 0) {
          return <div>{row?.client_data[0]?.areaCode_Data?.code || "-"}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "eng_Data",
      text: "engineer",
      sort: true,
      formatter: (cell, row) => {
        if (cell.length !== 0) {
          return (
            <div className="me-1">
              {cell[0]?.firstName ||
                (!cell[0]?.firstName && !cell[0]?.lastName && "N/A")}{" "}
              {cell[0]?.lastName || ""}
            </div>
          );
        } else if (row?.status === 3) {
          return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
            By Admin
          </span>
        } else {
          return <div className="ms-1">N/A</div>;
        }
      },
    },
    {
      dataField: "createdAt",
      text: "complaint Time",
      sort: true,
      formatter: (cell, row) => {
        return <div>{ cell ? moment(cell).format("DD-MM-YY, h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "assignTime",
      text: "Assign Time",
      sort: true,
      formatter: (cell, row) => {
        return <div>{ cell ? moment(cell).format("DD-MM-YY, h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "startTime",
      text: "Running Time",
      sort: true,
      formatter: (cell, row) => {
        return <div>{ cell ? moment(cell).format("DD-MM-YY, h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "endTime",
      text: "Completed Time",
      sort: true,
      formatter: (cell, row) => {
        return <div>{ cell ? moment(cell).format("DD-MM-YY, h:mm:ss a") : "N/A"}</div>;
      },
    },
    {
      dataField: "status",
      text: "status",
      sort: true,
      formatter: (cell, row) => {
        if (cell === 0) {
          return (
            <span
              className="label font-weight-bold label-lg label-light-warning label-inline"
              role="button"
              onClick={() => handleStatus(row)}
            >
              Pending
            </span>
          );
        }
        if (cell === 1) {
          return (
            <span
              className="label font-weight-bold label-lg label-light-success label-inline"
              role="button"
              onClick={() => handleStatus(row)}
            >
              Assigned
            </span>
          );
        }
        if (cell === 2) {
          return (
            <span className="label font-weight-bold label-lg label-light-info label-inline">
              Running
            </span>
          );
        }
        if (cell === 3) {
          return (
            <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
              Completed
            </span>
          );
        }
      },
    },
    {
      dataField: "available",
      text: "Media",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {(row?.audio || row?.video) ? <a
              title="Video/Audio"
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => {
                setVideoModal(true);
                setNewData({ video: row?.video, audio: row?.audio });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")} />
              </span>
            </a> : <div>N/A</div>}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {/* {(row?.audio || row?.video) && <a
              title="Video/Audio"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-2"
              onClick={() => {
                setVideoModal(true);
                setNewData({ video: row?.video, audio: row?.audio });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Play.svg")} />
              </span>
            </a>} */}
            <a
              title="View Complaint"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-2"
              onClick={() => history.push("/complaint-details/" + row?._id)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
                <AiFillEye className="" title="View Complaint" />
              </span>
            </a>
            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm me-2"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
            <a
              title="Complete customer"
              className="btn btn-icon btn-light btn-hover-success btn-sm"
              onClick={() => {
                row?.status === 3 ? ErrorToast("This Complaint Already Completed!") :
                  Swal.fire({
                    text: `Are you sure you want to Complete it ?`,
                    icon: "warning",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Yes, Complete`,
                    confirmButtonColor: "#D72852",
                    cancelButtonColor: "transparent",
                    cancelButtonText: "No, Cancel",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      onComplate(row)
                      // onDelete(row?._id);
                    }
                  });
              }}
            >
              <MdSwapHoriz color="green" size="30" />
              {/* <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/navigation/exchange.svg")}
                />
              </span> */}
            </a>
          </div>
        );
      },
    },
  ];
  console.log("newData", newData);
  const handleChangeMachine = (e) => {
    let data = machineData.filter((v) => {
      if (v?._id === e?.value) {
        return v;
      }
    });
    setMachineDataValue(e?.value ?? "");
    setMachineDataClientValue(data[0]?.client_data[0]);
  };
  const handleChangeIssue = (e) => {
    setIssueDataValue(e.target.value);
  };

  const handleSubmitComplaint = () => {
    let body = {}
    if (issueDataValue === "other") {
      body = {
        machineId: machineDataValue,
        clientId: machineDataClientValue?._id,
        description: discription
      }
    } else {
      body = {
        machineId: machineDataValue,
        clientId: machineDataClientValue?._id,
        issueType: issueDataValue
      }
    }
    console.log('body', body)
    ApiPost("/addcomplaint", body)
      .then((res) => {
        console.log('res', res)
        SuccessToast(res?.data?.message)
        fetchData(
          currentpage,
          pagesize,
          searching,
          playStatus?.number,
          playMachineStatus,
          playClientId?.id,
          playEngId?.id,
          playStartDate,
          playEndDate
        );
        setModal1(false)
        setMachineDataValue("")
        setIssueDataValue("")
        setDiscription("")
        setMachineDataClientValue({})
      }).catch((err) => {
        console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  const validate = (values) => {
    const err = {};
    if (!values.firstName) {
      err.firstName = "First Name required!";
    }
    if (!values.lastName) {
      err.lastName = "Last name required!";
    }
    if (!values.email) {
      err.email = "Email address required!";
    }
    if (!values.phoneNumber) {
      err.phoneNumber = "Phone number required!";
    }
    setError(err);
    if (Object.keys(err).length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleStatus = (row) => {
    setStatusModal(true);
    setNewData({
      id: row?._id,
      engId: "",
    });
    setAssignComp([]);
  };
  const handleOnChangeStatus = (e) => {
    setcurrentpage(1);
    let a = JSON.parse(e);
    setPlayStatus(a);
    // setByPlaylist(item?._id);
    fetchData(
      1,
      pagesize,
      searching,
      a?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      playStartDate,
      playEndDate
    );
  };
  const handleOnChangeWarranty = (e) => {
    setcurrentpage(1);
    let a = JSON.parse(e);
    console.log('aaaaaaaaaaaaaaaaaa', a)
    let b = null;
    if (a.name === "Warranty") {
      b = 1;
      setPlayMachineStatus(1);
    } else if (a.name === "AMC") {
      b = 2;
      setPlayMachineStatus(2);
    } else if (a.name === "No Warranty") {
      b = 3;
      setPlayMachineStatus(3);
    } else if(a.name === "Filter Warranty"){
      b=null;
      setPlayMachineStatus(null)
    }
    setPlayWarranty(a);
    fetchData(
      1,
      pagesize,
      searching,
      playStatus?.number,
      b,
      playClientId?.id,
      playEngId?.id,
      playStartDate,
      playEndDate
    );
  };
  const handleOnChangeEngineer = (e) => {
    console.log('e', e)
    setcurrentpage(1);
    let a = e;
    setPlayEngId({
      name:e?.lable || "",
      id:e?.value || ""
    });
    fetchData(
      1,
      pagesize,
      searching,
      playStatus?.number,
      playMachineStatus,
      playClientId?.id,
      a?.value,
      playStartDate,
      playEndDate
    );
  };
  const handleOnChangeClient = (e) => {
    console.log('e', e)
    setcurrentpage(1);
    let a = e
    setPlayClientId({
      name:e?.lable || "",
      id:e?.value || ""
    });
    fetchData(
      1,
      pagesize,
      searching,
      playStatus?.number,
      playMachineStatus,
      a?.value,
      playEngId?.id,
      playStartDate,
      playEndDate
    );
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNewData({ ...newData, [name]: value });
  };
  const editBtnClick = (row) => {
    setIsEdit(true);
    setError({});
    setModal(true);
    setNewData({
      id: row?._id,
      firstName: row?.firstName,
      lastName: row?.lastName,
      phoneNumber: row?.phoneNumber,
      email: row?.email,
      engId: "",
    });
  };
  const onApply = (event, picker) => {
    setcurrentpage(1);
    let sDate = new Date(
      new Date(picker?.startDate?._d).setDate(
        new Date(picker?.startDate?._d).getDate() + 1
      )
    );
    setPlayStartDate(sDate);
    setPlayEndDate(new Date(picker?.endDate?._d));
    fetchData(
      currentpage,
      pagesize,
      searching,
      playStatus?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      sDate,
      new Date(picker?.endDate?._d)
    );
  };
  const onCancel = (event, picker) => {
    setcurrentpage(1);
    fetchData(
      currentpage,
      pagesize,
      searching,
      playStatus?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      null,
      null
    );
  };
  const submitAssign = async () => {
    if (newData?.engId) {
      setError({});
    } else {
      setError({ engId: "engineer required!" });
      return;
    }
    setFLoading(true);
    let body = {
      id: newData?.id,
      engId: newData?.engId,
      status: 1,
    };
    await ApiPut("/complaint", body)
      .then((res) => {
        SuccessToast("Complaint Assign Successfully !!!");
        fetchData(
          currentpage,
          pagesize,
          searching,
          playStatus?.number,
          playMachineStatus,
          playClientId?.id,
          playEngId?.id,
          playStartDate,
          playEndDate
        );
        setStatusModal(false);
        setFLoading(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFLoading(false);
      });
  };

  const getMachine = async () => {
    await ApiGet("/allMachines")
      .then(async (res) => {
        console.log("res", res);
        setMachineData(res?.data?.data);
        // setClients(res?.data?.data)
        const arr = []
        const a = await res?.data?.data?.map((v, i) => {
          arr.push({ value: v?._id, label: `${v?.machineNo}` })
        })
        setOptions(arr)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getIssueType = async () => {
    await ApiGet("/issueType")
      .then((res) => {
        console.log("res", res);
        setIssueData(res?.data?.data?.response);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const onDelete = async (Id) => {
    await ApiDelete(`/complaint/${Id}`)
      .then(() => {
        Swal.fire({
          text: "Complaint Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(
          currentpage,
          pagesize,
          searching,
          playStatus?.number,
          playMachineStatus,
          playClientId?.id,
          playEngId?.id,
          playStartDate,
          playEndDate
        );
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onComplate = async (row) => {
    let body = {
      id: row?._id,
      status: 3
    }
    if (row?.status === 3) {
      ErrorToast("This Complaint Already Completed!")
    } else {
      await ApiPut(`/complaints`, body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          fetchData(
            currentpage,
            pagesize,
            searching,
            playStatus?.number,
            playMachineStatus,
            playClientId?.id,
            playEngId?.id,
            playStartDate,
            playEndDate
          );
        })
        .catch((err) => ErrorToast(err?.message));
    }
  };
  const onUpdate = async () => {
    if (validate(newData)) {
      setFlag(true);
      return;
    } else {
      setFlag(false);
    }
    let body = {
      id: newData?.id,
      firstName: newData?.firstName.trim(),
      lastName: newData?.lastName.trim(),
      phoneNumber: newData?.phoneNumber.trim(),
      email: newData?.email.trim(),
    };
    console.log("body", body);
    await ApiPut("/Eng", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(
          currentpage,
          pagesize,
          searching,
          playStatus?.number,
          playMachineStatus,
          playClientId?.id,
          playEngId?.id,
          playStartDate,
          playEndDate
        );
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const handleSubmit = async () => {
    if (validate(newData)) {
      setFlag(true);
      return;
    } else {
      setFlag(false);
    }
    let body = {
      firstName: newData?.firstName.trim(),
      lastName: newData?.lastName.trim(),
      phoneNumber: newData?.phoneNumber.trim(),
      email: newData?.email.trim(),
    };
    await ApiPost("/Eng", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(
          currentpage,
          pagesize,
          searching,
          playStatus?.number,
          playMachineStatus,
          playClientId?.id,
          playEngId?.id,
          playStartDate,
          playEndDate
        );
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(
      currentpage,
      parseInt(e.target.value),
      searching,
      playStatus?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      playStartDate,
      playEndDate
    );
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(
      i,
      pagesize,
      searching,
      playStatus?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      playStartDate,
      playEndDate
    );
  };
  const handlesearch = (e) => {
    setsearching(e.target.value);
    setcurrentpage(1);
    fetchData(
      1,
      pagesize,
      e.target.value,
      playStatus?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      playStartDate,
      playEndDate
    );
  };
  const handleSelectEng = async (e) => {
    if (e.target.value === "") {
      setAssignComp([]);
      return;
    }
    await ApiGet("/complaint/" + e.target.value)
      .then((res) => {
        setAssignComp(res?.data?.data);
      })
      .catch((err) => {
        ErrorToast(err?.message);
      });
  };
  const getAllClient = async () => {
    await ApiGet("/Client")
      .then(async(res) => {
        setAllClientData(res?.data?.data);
        const arr = []
				const a = await res?.data?.data?.map((v, i) => {
					arr.push({ value: v?._id, label: `${v?.firstName} ${v?.lastName}` })
				})
        setOptionsClient(arr)
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const getAllEngineer = async () => {
    await ApiGet("/Eng")
      .then(async(res) => {
        setAllEngData(res?.data?.data);
        const arr = []
				const a = await res?.data?.data?.map((v, i) => {
					arr.push({ value: v?._id, label: `${v?.firstName} ${v?.lastName}` })
				})
        setOptionsEng(arr)
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const getNotAssignEngineer = async () => {
    await ApiGet("/assignEng")
      .then((res) => {
        setEngData(res?.data?.data);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const fetchData = async (
    page,
    limit,
    search,
    status,
    machineStatus,
    clientId,
    engId,
    startDate,
    endDate
  ) => {
    let body = {
      page,
      limit,
      search,
      status,
      machineStatus,
      clientId,
      engId,
      startDate,
      endDate,
    };
    setFetching(true);
    await ApiPost("/complaint", body)
      .then((res) => {
        console.log("/complaint", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };

  useEffect(() => {
    console.log("location", location);
    let newStatus = playStatus;
    if (location.state) {
      newStatus = location.state;
      setPlayStatus(location.state);
      if(location.state?.today){
        setPlayStartDate(new Date(new Date().setHours(0,0,0,0)))
        setPlayEndDate(new Date(new Date().setHours(23,59,59,999)))
      }
    }
    fetchData(
      currentpage,
      pagesize,
      searching,
      newStatus?.number,
      playMachineStatus,
      playClientId?.id,
      playEngId?.id,
      location.state?.today?new Date(new Date().setHours(0,0,0,0)) :playStartDate,
      location.state?.today?new Date(new Date().setHours(23,59,59,999)) :playEndDate
    );
    getNotAssignEngineer();
    getAllEngineer();
    getAllClient();
    getMachine();
    getIssueType();
  }, []);
  // useEffect(() => {
  //   const messaging = firebase.messaging()
  //   messaging.onMessage((data)=>{
  //     console.log("dfgdfgdfgdfgdfgdfgdfgdf",data)
  //     setTimeout(() => {
  //       if(data.data.type=="4"){
  //         toast.success(data.notification.body)
  //         if(window.location.pathname=="/complaint"){

  //         }else{
  //           fetchData(
  //             currentpage,
  //             pagesize,
  //             searching,
  //             playMachineStatus,
  //             playClientId?.id,
  //             playEngId?.id,
  //             playStartDate,
  //             playEndDate
  //           );
  //         }
          
  //      }
  //   //    if(data.data.type=="5"){
  //   //     toast.error(data.notification.body)

  //   //     history.push("/complaint")
  //   //  }
  //     }, 2000);
  //   })
  //   })
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Complaint</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Complaint
            </span>
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-column-fluid h-100"
        id="kt_content"
      >
        <div className="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title" style={{ width: "100%" }}>
              <div class="row align-items-center w-100">
                <div className="col-md-2 my-2">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Search by Serial Number, Machine Number and Complaint
                        Number
                      </Tooltip>
                    }
                  >
                    <div class="input-icon">
                      <input
                        type="text"
                        class="form-control bg-light"
                        name="searchText"
                        placeholder="Search by..."
                        value={searching}
                        onChange={handlesearch}
                        id="kt_datatable_search_query"
                      />
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="col-md-2 my-2">
                  <Dropdown
                    onSelect={handleOnChangeStatus}
                  // defaultValue="request"
                  >
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-capitalize"
                    >
                      {playStatus?.name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: "100%" }}>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Filter Status",
                          number: null,
                        })}
                      >
                        None of these
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Pending",
                          number: 0,
                        })}
                      >
                        Pending
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({ name: "Assign", number: 1 })}
                      >
                        Assign
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Running",
                          number: 2,
                        })}
                      >
                        Running
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Complete",
                          number: 3,
                        })}
                      >
                        Complete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-md-2 my-2">
                  <Dropdown onSelect={handleOnChangeWarranty}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-capitalize"
                    >
                      {playWarranty?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ minWidth: "100%" }}>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Filter Warranty",
                          bool: null,
                        })}
                      >
                        None of these
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Warranty",
                          bool: true,
                        })}
                      >
                        Warranty
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({ name: "AMC", bool: true })}
                      >
                        AMC
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "No Warranty",
                          bool: false,
                        })}
                      >
                        Out of Warranty
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-md-2 my-2">
                  {/* <Dropdown onSelect={handleOnChangeEngineer}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-capitalize"
                    >
                      {playEngId?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ minWidth: "100%" }}>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Filter By Engineer",
                          id: "",
                        })}
                      >
                        None of these
                      </Dropdown.Item>
                      {allEngData?.map((v, i) => {
                        return (
                          <Dropdown.Item
                            key={v?._id}
                            eventKey={JSON.stringify({
                              name: `${v?.firstName} ${v?.lastName}`,
                              id: v?._id,
                            })}
                          >
                            {v?.firstName || ""} {v?.lastName || ""}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Select
								className=""
								// defaultValue={isEdit && editOption}
								onChange={(e) => handleOnChangeEngineer(e)}
								placeholder="Select Engineer"
								isClearable={true}
								isRtl={false}
								isSearchable={true}
								name="color"
								options={optionsEng}
								classNames={{
									control: (provided, state) => ({
										...provided,
                    fontSize:"15px",
										border: state.isActive ? '2px solid red' : provided.border,
									}),
								}}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  fontSize:"10px",
                })}
							/>
                </div>
                <div className="col-md-2 my-2">
                  {/* <Dropdown onSelect={handleOnChangeClient}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="text-capitalize"
                    >
                      {playClientId?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ minWidth: "100%" }}>
                      <Dropdown.Item
                        eventKey={JSON.stringify({
                          name: "Filter By Client",
                          id: "",
                        })}
                      >
                        None of these
                      </Dropdown.Item>
                      {allClientData?.map((v, i) => {
                        return (
                          <Dropdown.Item
                            key={v?._id}
                            eventKey={JSON.stringify({
                              name: `${v?.firstName} ${v?.lastName}`,
                              id: v?._id,
                            })}
                          >
                            {v?.firstName || ""} {v?.lastName || ""}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown> */}
                  <Select
								className=""
								// defaultValue={isEdit && editOption}
								onChange={(e) => handleOnChangeClient(e)}
								placeholder="Select Client"
								isClearable={true}
								isRtl={false}
								isSearchable={true}
								name="color"
								options={optionsClient}
								classNames={{
									control: (provided, state) => ({
										...provided,
                    fontSize:"15px",
										border: state.isActive ? '2px solid red' : provided.border,
									}),
								}}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  fontSize:"10px",
                })}
							/>
                </div>
                <div className="col-md-2 my-2 pe-0">
                  <DateRangePicker onApply={onApply} onCancel={onCancel}>
                    <input
                      type="text"
                      className="form-control"
                      autoUpdateInput={false}
                      locale={{
                        cancelLabel: "Clear",
                      }}
                    />
                  </DateRangePicker>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div className="card-toolbar">
              <button
                className="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setModal1(true);
                }}
              >
                Add Complaint
              </button>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              noDataIndication={() => {
                if (fetching) {
                  return (
                    <div className="text-center">
                      <img src={loader} className="h-100px" alt="loadingLogo" />
                    </div>
                  );
                } else {
                  return <NoDataTable />;
                }
              }}
            />
            <div className="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div className="my-2">
                <div className="d-flex align-items-center pagination-drpdown">
                  <select
                    className="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => setModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add New"} Engineer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-6">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                label="firstName"
                id="firstName"
                required
                name="firstName"
                onChange={handleChange}
                value={newData?.firstName}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.firstName && (
                <span className="errorInput">{error["firstName"]}</span>
              )}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                label="lastName"
                id="lastName"
                required
                name="lastName"
                onChange={handleChange}
                value={newData?.lastName}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.lastName && (
                <span className="errorInput">{error["lastName"]}</span>
              )}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Address"
                id="email1"
                className="form-control"
                required
                name="email"
                onChange={handleChange}
                value={newData?.email}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
                autoComplete="off"
              />
              {error?.email && (
                <span className="errorInput">{error["email"]}</span>
              )}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                label="phoneNumber"
                id="phoneNumber"
                required
                name="phoneNumber"
                onChange={handleChange}
                value={newData?.phoneNumber}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.phoneNumber && (
                <span className="errorInput">{error["phoneNumber"]}</span>
              )}
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button
              className="btn btn-color"
              onClick={onUpdate}
              disabled={fLoading}
            >
              Update Changes
            </button>
          ) : (
            <button
              className="btn btn-color"
              onClick={handleSubmit}
              disabled={fLoading}
            >
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={statusModal}
        onHide={() => setStatusModal(false)}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Engineer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label></Form.Label>
              <select
                className="form-select"
                placeholder="Enter First Name"
                label="engId"
                id="engId"
                required
                name="engId"
                onChange={(e) => {
                  handleChange(e);
                  handleSelectEng(e);
                }}
                value={newData?.engId}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
                role="button"
              >
                <option value="" disabled>
                  Select Engineer
                </option>
                {engData?.map((v, i) => (
                  <option value={v?._id} key={i}>
                    {v?.firstName} {v?.lastName}
                  </option>
                ))}
              </select>
              {error?.engId && (
                <span className="errorInput">{error["engId"]}</span>
              )}
            </Form.Group>
            {assignComp.length !== 0 && (
              <div className="px-3">
                <h3 className="text-center mb-3">Assigned Complaints</h3>
                <table className="w-100 table-bordered table">
                  <thead className="bg-primary">
                    <tr>
                      <th className="text-center text-white fs-16px">
                        Complaint No
                      </th>
                      <th className="text-center text-white fs-16px">
                        Complaint Date
                      </th>
                      <th className="text-center text-white fs-16px">
                        Machine No
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-primary-o-30">
                    {assignComp?.map((v, i) => {
                      return (
                        <tr key={i}>
                          <td className="text-center">{v?.complaintNo}</td>
                          <td className="text-center">
                            {moment(v?.createdAt).format("DD-MM-YYYY")}
                          </td>
                          <td className="text-center">
                            {v?.machine_Data[0]?.machineNo}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-color" onClick={submitAssign}>
            Assign{" "}
            {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={videoModal} onHide={() => setVideoModal(false)} centered>
        <Modal.Body>
          <>
            <a
              className="fs-1 position-absolute"
              style={{ right: "34px", top: "15px" }}
              onClick={() => setVideoModal(false)}
            >
              x
            </a>
            {newData?.video && (
              <>
                <h3>Complaint Video</h3>
                <video
                  src={newData?.video || ""}
                  className="w-100 h-300px"
                  controls
                  loop
                ></video>
              </>
            )}
            {newData?.audio && (
              <>
                <h3 className="mt-5">Complaint Audio</h3>
                <audio
                  src={newData?.audio || ""}
                  className="w-100"
                  controls
                ></audio>
              </>
            )}
            {!newData?.video && !newData?.audio && (
              <>
                <h2 className="text-center m-0">Audio/Video Not Found</h2>
              </>
            )}
          </>
        </Modal.Body>
      </Modal>
      <Modal
        show={modal1}
        onHide={() => {
          setModal1(false);
          setMachineDataValue("");
          setIssueDataValue("");
        }}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Complaint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="col-md-12">
            <Form.Label>Machine</Form.Label>
            {/* <select
              className="form-select"
              placeholder="Enter Machine"
              label="engId"
              id="engId"
              required
              name="engId"
              onChange={(e) => handleChangeMachine(e)}
              value={machineDataValue}
              onBlur={() => {
                if (flag) {
                  validate(newData);
                }
              }}
              role="button"
            >
              <option value="" disabled>
                Select Machine
              </option>
              {machineData?.map((v, i) => (
                <option value={v?._id} key={i}>
                  {v?.machineNo}
                </option>
              ))}
            </select> */}
            {error?.engId && (
              <span className="errorInput">{error["engId"]}</span>
            )}
            <Select
              className="basic-single"
              // defaultValue={isEdit && editOption}
              onChange={(e) => handleChangeMachine(e)}
              placeholder="Select Client"
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="color"
              options={options}
              style={{
                control: (provided, state) => ({
                  ...provided,
                  border: state.isActive ? '2px solid red' : provided.border,
                }),
              }}
            />
          </Form.Group>
          {machineDataValue && (
            <Form.Group className="col-md-12">
              <Form.Label>Client</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                label="phoneNumber"
                id="phoneNumber"
                required
                disabled
                value={
                  machineDataClientValue?.firstName +
                  " " +
                  machineDataClientValue?.lastName
                }
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.engId && (
                <span className="errorInput">{error["engId"]}</span>
              )}
            </Form.Group>
          )}
          <Form.Group className="col-md-12">
            <Form.Label>Issue</Form.Label>
            <select
              className="form-select"
              placeholder="Enter Machine"
              label="engId"
              id="engId"
              required
              name="engId"
              onChange={(e) => handleChangeIssue(e)}
              value={issueDataValue}
              onBlur={() => {
                if (flag) {
                  validate(newData);
                }
              }}
              role="button"
            >
              <option value="" disabled>
                Select Issue
              </option>
              <option value="other">Other</option>
              {issueData?.map((v, i) => (
                <option value={v?._id} key={i}>
                  {v?.name}
                </option>
              ))}
            </select>
            {error?.engId && (
              <span className="errorInput">{error["engId"]}</span>
            )}
          </Form.Group>
          {issueDataValue === "other" && (
            <Form.Group className="col-md-12">
              <Form.Label>Discription</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Discription"
                label="phoneNumber"
                id="phoneNumber"
                as="textarea"
                rows={3}
                required
                value={discription}
                onChange={(e) => setDiscription(e.target.value)}
                onBlur={() => {
                  if (flag) {
                    validate(newData);
                  }
                }}
              />
              {error?.engId && (
                <span className="errorInput">{error["engId"]}</span>
              )}
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>

          <button className="btn btn-color" onClick={handleSubmitComplaint} disabled={fLoading}>
            <span>Submit</span>
            {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
