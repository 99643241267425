import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import { ApiDelete, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { ErrorToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import loader from "../../../media/loaderSkyline.gif";

export default function TrashClient() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [fetching, setFetching] = useState(false);

  const columns = [
    {
      dataField: "title",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div
            className="d-flex align-items-center"
            role="button"
            onClick={() => history.push("/client-details/" + row?._id)}
          >
            <div className="symbol symbol-50px symbol-light mr-4">
              <img src={row?.image} className="img-fluid object-cover" loading="lazy" />
            </div>
            <div>
              <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {row?.firstName || (!row?.firstName && !row?.lastName && "-")} {row?.lastName || ""}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "email",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "phoneNumber",
      text: "phone Number",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "areaCode",
      text: "area Code",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell[0]?.code || "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
      },
    },
    {
      dataField: "totalMachine",
      text: "Machines",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "0"}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            <a
              title="Restore"
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Restore ?`,
                  icon: "info",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Restore`,
                  confirmButtonColor: "#0095e8",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onRestore(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Repeat.svg")} title="Restore" />
              </span>
            </a>

            <a
              title="Delete"
              className="btn btn-icon btn-light btn-hover-danger btn-sm mx-2"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to permanently Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title="Delete" />
              </span>
            </a>
          </>
        );
      },
    },
  ];
  const onDelete = async (Id) => {
    await ApiDelete("/trashClient/" + Id)
      .then(() => {
        Swal.fire({
          text: "Client Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onEmptyTrash = async () => {
    Swal.fire({
      text: `Are you sure you want to Empty Trash ?`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `Yes, Delete`,
      confirmButtonColor: "#D72852",
      cancelButtonColor: "transparent",
      cancelButtonText: "No, Cancel",
    }).then((res) => {
      if (res.isConfirmed) {
        ApiDelete("/trashClient/")
          .then(() => {
            Swal.fire({
              text: "All Clients Deleted Successfully!!!",
              icon: "success",
              confirmButtonText: "Ok, Got it!",
              confirmButtonColor: "#338DE6",
            });
            fetchData(currentpage, pagesize, searching);
          })
          .catch((err) => ErrorToast(err?.message));
      }
    });

  };
  const onRestore = async (Id) => {
    await ApiPut("/trashClient/" + Id, {})
      .then(() => {
        Swal.fire({
          text: "Client Retrieve Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const handlesearch = (e) => {
    setcurrentpage(1);
    setsearching(e.target.value);
    fetchData(1, pagesize, e.target.value);
  };

  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    setFetching(true);
    await ApiPost("/getTrashClients", body)
      .then((res) => {
        console.log("/getTrashClients", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Clients Trash</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Trash Clients
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div className="card card-custom">
          <div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div className="card-title ">
              <div className="input-icon">
                <input
                  type="text"
                  className="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Name"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i className="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div className="card-toolbar">
              <button className="btn btn-color font-weight-bolder btn-sm" onClick={onEmptyTrash}>Empty Trash</button>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              noDataIndication={() => {
                if (fetching) {
                  return (
                    <div className="text-center">
                      <img src={loader} className="h-100px" alt="loadingLogo" />
                    </div>
                  );
                } else {
                  return <NoDataTable />;
                }
              }}
            />
            <div className="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div className="my-2">
                <div className="d-flex align-items-center pagination-drpdown">
                  <select
                    className="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
