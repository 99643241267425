import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { ApiGet, ApiPut } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { ImSpinner9 } from 'react-icons/im'

function ComplaintDetails() {
	const history = useHistory();
	const { id } = useParams();
	const [data, setData] = useState({});
	const [machineData, setMachineData] = useState({});
	const [clientData, setClientData] = useState({});
	const [engData, setEngData] = useState({});
	const [newData, setNewData] = useState({});
	const [assignComp, setAssignComp] = useState([]);
	const [statusModal, setStatusModal] = useState(false);
	const [fLoading, setFLoading] = useState(false);
	const [notEngData, setNotEngData] = useState([]);

	const handleStatus = () => {
		setStatusModal(true);
		setNewData({
			id: data?._id,
			engId: "",
		});
		setAssignComp([]);
	}
	const handleChange = (e) => {
		const { value, name } = e.target;
		setNewData({ ...newData, [name]: value });
	};
	const handleSelectEng = async (e) => {
		if (e.target.value === "") { setAssignComp([]); return; }
		await ApiGet("/complaint/" + e.target.value)
			.then(res => { setAssignComp(res?.data?.data); })
			.catch(err => { ErrorToast(err?.message) })
	}
	const submitAssign = async () => {
		setFLoading(true)
		let body = {
			id: newData?.id,
			engId: newData?.engId,
			status: 1
		}
		await ApiPut("/complaint", body)
			.then(res => {
				SuccessToast("Complaint Assign Successfully !!!");
				fetchData();
				setStatusModal(false);
				setFLoading(false);
			})
			.catch(err => { ErrorToast(err?.message); setFLoading(false); })
	}
	const getNotAssignEngineer = async () => {
		await ApiGet("/assignEng")
			.then(res => { setNotEngData(res?.data?.data) })
			.catch(err => ErrorToast(err?.message))
	}
	const fetchData = async () => {
		await ApiGet("/getComplaint/" + id)
			.then((res) => {
				console.log("/complaint", res?.data?.data[0]);
				setData(res?.data?.data[0]);
				if (res?.data?.data[0]?.machine_Data?.length > 0) {
					setMachineData(res?.data?.data[0]?.machine_Data[0]);
				}
				if (res?.data?.data[0]?.client_data?.length > 0) {
					setClientData(res?.data?.data[0]?.client_data[0]);
				}
				if (res?.data?.data[0]?.eng_Data?.length > 0) {
					setEngData(res?.data?.data[0]?.eng_Data[0]);
				}
			})
			.catch((err) => { ErrorToast(err?.message); });
	};
	useEffect(() => {
		fetchData();
		getNotAssignEngineer();
	}, [])
	console.log('ata?.issueType[0]?.name', data.issueType?.length > 0 && data.issueType[0])
	return (<>
		<div className="d-flex justify-content-between mb-4">
			<div className="title">
				<div className="fs-20px fw-bolder">Complaint Details</div>
				<div>
					<span
						role="button"
						onClick={() => history.push("/dashboard")}
						className="text-hover-info text-muted"
					>
						Home
					</span>{" "}
					-{" "}
					<span
						role="button"
						onClick={() => history.push("/complaint")}
						className="text-hover-info text-muted"
					>
						{" "}
						Complaints
					</span>{" "}
					-{" "}
					<span role="button" className="text-muted">
						{" "}
						Complaint details
					</span>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-md-6">
				<div className="card h-100 shadow">
					<div className="card-body">
						<h3>Complaint Details</h3>
						<div class="row mb-3 mt-7">
							<label class="col-lg-4 fw-bold text-muted">Complaint Number</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{data?.complaintNo || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Issue</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{data.issueType?.length > 0 ? data.issueType[0]?.name : "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Description</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{data?.description || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Complaint Date</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{data?.createdAt ? moment(data?.createdAt).format("DD-MM-YYYY hh:mm:ss A") : "-"}</span>
							</div>
						</div>
						{(data?.status !== 0 && data?.assignTime) && (
							<div class="row mb-3">
								<label class="col-lg-4 fw-bold text-muted">Assign Date</label>
								<div class="col-lg-8">
									<span class="fw-bolder fs-6 text-gray-800">{data?.assignTime ? moment(data?.assignTime).format("DD-MM-YYYY hh:mm:ss A") : "-"}</span>
								</div>
							</div>
						)}
						{(data?.status !== 0 && data?.status !== 1 && data?.startTime) && (
							<div class="row mb-3">
								<label class="col-lg-4 fw-bold text-muted">Running Date</label>
								<div class="col-lg-8">
									<span class="fw-bolder fs-6 text-gray-800">{data?.startTime ? moment(data?.startTime).format("DD-MM-YYYY hh:mm:ss A") : "-"}</span>
								</div>
							</div>
						)}
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Status</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">
									{data?.status === 0 ? (
										<span className="label font-weight-bold label-lg label-light-warning label-inline" role="button" onClick={handleStatus}>Pending</span>
									) : data?.status === 1 ? (
										<span className="label font-weight-bold label-lg label-light-success label-inline" role="button" onClick={handleStatus}>Assigned</span>
									) : data?.status === 2 ? (
										<span className="label font-weight-bold label-lg label-light-info label-inline">Running</span>
									) : data?.status === 3 && (
										<span className="label font-weight-bold label-lg bg-light-success text-success label-inline">Completed</span>
									)}
								</span>
							</div>
						</div>
						{data?.status === 3 && (
							<div class="row">
								<label class="col-lg-4 fw-bold text-muted">Completed Date</label>
								<div class="col-lg-8">
									<span class="fw-bolder fs-6 text-gray-800">{data?.endTime ? moment(data?.endTime).format("DD-MM-YYYY hh:mm:ss A") : "-"}</span>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>
			<div className="col-md-6">
				{Object.keys(engData).length !== 0 && <div className="card h-100 shadow">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<h3>Engineer Details</h3>
							<button className="btn btn-sm btn-primary" onClick={() => history.push("/engineer-details/" + engData?._id)}>View Engineer</button>
						</div>
						<div class="row mb-3 mt-7">
							<label class="col-lg-4 fw-bold text-muted">Engineer Name</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{engData?.firstName || "-"} {engData?.lastName || ""}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Email Address</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{engData?.email || "-"}</span>
							</div>
						</div>
						<div class="row">
							<label class="col-lg-4 fw-bold text-muted">Phone No.</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{engData?.phoneNumber || "-"}</span>
							</div>
						</div>
					</div>
				</div>}
			</div>
		</div>
		<div className="row mt-7">
			<div className="col-md-6">
				<div className="card h-100 shadow">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<h3>Machine Details</h3>
							<button className="btn btn-sm btn-primary" onClick={() => history.push("/machine-details/" + machineData?._id)}>View Machine</button>
						</div>
						<img src={machineData?.image?.length > 0 && machineData?.image[0]} className="rounded" width="100" height="100" alt="" />
						{/* <div class="row mb-3 mt-7">
							<label class="col-lg-4 fw-bold text-muted">Machine Name</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.machineName || "-"}</span>
							</div>
						</div> */}
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Machine Number</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.machineNo || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Serial Number</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.serialNumber || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Machine Type</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.machineType_data?.length > 0 ? machineData?.machineType_data[0]?.name : "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Description</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.description || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Buy Date</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.buyDate ? moment(machineData?.buyDate).format("DD-MM-YYYY") : "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Expiry Date</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.expDate ? moment(machineData?.expDate).format("DD-MM-YYYY") : "-"}</span>
							</div>
						</div>
						<div class="row">
							<label class="col-lg-4 fw-bold text-muted">Warranty</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{machineData?.isWarranty ? "true" : "false"}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className="card h-100 shadow">
					<div className="card-body">
						<div className="d-flex justify-content-between">
							<h3>Client Details</h3>
							<button className="btn btn-sm btn-primary" onClick={() => history.push("/client-details/" + clientData?._id)}>View Client</button>
						</div>
						<img src={clientData?.image} className="rounded" width="100" height="100" alt="" />
						<div class="row mb-3 mt-7">
							<label class="col-lg-4 fw-bold text-muted">Client Name</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{clientData?.firstName || "-"} {clientData?.lastName || ""}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Email Address</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{clientData?.email || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Phone No.</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{clientData?.phoneNumber || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Address</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{clientData?.address || "-"}</span>
							</div>
						</div>
						<div class="row mb-3">
							<label class="col-lg-4 fw-bold text-muted">Area Code</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{clientData?.areaCode_data?.length > 0 ? clientData?.areaCode_data[0]?.code : "-"}</span>
							</div>
						</div>
						<div class="row">
							<label class="col-lg-4 fw-bold text-muted">Company Name</label>
							<div class="col-lg-8">
								<span class="fw-bolder fs-6 text-gray-800">{clientData?.companyName || "-"}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal show={statusModal} onHide={() => setStatusModal(false)} backdrop="static" centered>
			<Modal.Header closeButton>
				<Modal.Title>Assign Engineer</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="row">
					<Form.Group className="col-md-12">
						<Form.Label></Form.Label>
						<select
							className="form-select"
							placeholder="Enter First Name"
							label="engId"
							id="engId"
							required
							name="engId"
							onChange={(e) => { handleChange(e); handleSelectEng(e) }}
							value={newData?.engId}
							role="button"
						>
							<option value="" disabled>Select Engineer</option>
							{notEngData?.map((v, i) => <option value={v?._id} key={i}>{v?.firstName} {v?.lastName}</option>)}
						</select>
					</Form.Group>
					{assignComp.length > 0 &&
						<div className="px-3">
							<h3 className="text-center mb-3">Assigned Complaints</h3>
							<table className="w-100 table-bordered table">
								<thead className="bg-primary">
									<tr>
										<th className="text-center text-white fs-16px">Complaint No</th>
										<th className="text-center text-white fs-16px">Complaint Date</th>
										<th className="text-center text-white fs-16px">Machine No</th>
									</tr>
								</thead>
								<tbody className="bg-primary-o-30">
									{assignComp?.map((v, i) => {
										return (
											<tr key={i}>
												<td className="text-center">{v?.complaintNo}</td>
												<td className="text-center">{moment(v?.createdAt).format("DD-MM-YYYY")}</td>
												<td className="text-center">{v?.machine_Data[0]?.machineNo}</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-color" onClick={submitAssign}>
					Assign {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
				</button>
			</Modal.Footer>
		</Modal>
	</>);
}

export default ComplaintDetails;
