import moment from "moment";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useParams } from "react-router-dom";
import { ApiGet, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import loader from "../../../media/loaderSkyline.gif";
import NoDataTable from "../../../common/noDataTable";
import { AiFillEye } from "react-icons/ai";
import { Pagination } from "@material-ui/lab";

export default function MachineDetails() {
  const history = useHistory();
  const { id } = useParams();
  const [toggle, setToggle] = useState("personal");
  const [data, setData] = useState({});
  const [clientData, setClientData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [macCountData, setMacCountData] = useState(null);
  const [complaintsData, setComplaintsData] = useState({});
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);

  const complaintColumns = [
    {
      dataField: "complaintNo",
      text: "complaint Number",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || ""}</div>;
      },
    },
    {
      dataField: "issueType",
      text: "issue",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-truncate" style={{ width: "120px" }}>
            {row?.issueType[0]?.name || "-"}
          </div>
        );
      },
    },
    {
      dataField: "description",
      text: "description",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="text-truncate" style={{ width: "120px" }}>
            {cell || "-"}
          </div>
        );
      },
    },
    {
      dataField: "eng_Data",
      text: "Engineer",
      sort: true,
      formatter: (cell, row) => {
        if (cell?.length > 0) {
          return (
            <div>
              {cell[0]?.firstName || "-"} {cell[0]?.lastName || ""}
            </div>
          );
        }else if (row?.status === 3) {
          return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
            By Admin
          </span>
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      dataField: "status",
      text: "status",
      sort: true,
      formatter: (cell, row) => {
        if (cell === 0) {
          return (
            <span className="label font-weight-bold label-lg label-light-warning label-inline">
              Pending
            </span>
          );
        }
        if (cell === 1) {
          return (
            <span className="label font-weight-bold label-lg label-light-success label-inline">
              Assigned
            </span>
          );
        }
        if (cell === 2) {
          return (
            <span className="label font-weight-bold label-lg label-light-info label-inline">
              Running
            </span>
          );
        }
        if (cell === 3) {
          return (
            <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
              Completed
            </span>
          );
        }
      },
    },
    {
      dataField: "view",
      text: "view",
      sort: true,
      formatter: (cell, row) => {
        return (<>
          <a
            title="View User"
            className="btn btn-icon btn-light btn-hover-primary btn-sm"
            onClick={() => history.push("/complaint-details/" + row?._id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
              <AiFillEye className="" />
            </span>
          </a>
        </>)
      }
    }
  ];
  const fetchData = async () => {
    setFetching(true);
    await ApiGet("/machine/" + id)
      .then((res) => {
        console.log("machine/Id", res?.data?.data[0]);
        setData(res?.data?.data[0]);
        if (res?.data?.data[0]?.client_data?.length > 0) {
          setClientData(res?.data?.data[0]?.client_data[0]);
        }
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };
  console.log("clientData", clientData);
  useEffect(() => {
    fetchData();
  }, []);

  const handleCountData = async () => {
    setFetching(true);
    await ApiGet("/machineCount/" + id)
      .then((res) => {
        setMacCountData(res?.data?.data);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };
  useEffect(() => {
    handleCountData()
  }, []);

  const fetchComplaintsData = async () => {
    setFetching(true);
    let body = {
      machineId: id,
      page: currentpage,
      limit: pagesize
    }
    await ApiPost("/maComplaints", body)
      .then((res) => {
        console.log("maComplaints/Id", res?.data?.data?.response);
        setComplaintsData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
        setFetching(false);
      })
      .catch((err) => {
        ErrorToast(err?.message);
        setFetching(false);
      });
  };

  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    // fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    console.log('e', i)
    setcurrentpage(i);
    // fetchData(i, pagesize, searching);
  };

  useEffect(() => {
    fetchComplaintsData();
  }, [currentpage,pagesize]);


  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Machine Details</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span
              role="button"
              onClick={() => history.push("/machine")}
              className="text-hover-info text-muted"
            >
              {" "}
              Machines
            </span>{" "}
            -{" "}
            <span role="button" className="text-muted">
              {" "}
              Machine details
            </span>
          </div>
        </div>
      </div>
      <div className="row d-flex">
        <div className="col-lg-4 card mb-5 mb-xl-10 shadow">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap mb-3">
              <div className="me-7 mb-4">
                <div className="">
                  <img
                    className="object-cover rounded"
                    height="160px"
                    width="160px"
                    src={
                      (data?.image?.length > 0 && data?.image[0]) ||
                      "https://res.cloudinary.com/deh7sqhvn/image/upload/v1675313330/1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg"
                    }
                    alt=""
                  />
                </div>
              </div>
              {/* <div>
              <div className="mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1 text-capitalize">
                      {data?.machineName || "-"}
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
            <div class="d-flex overflow-auto h-55px">
              <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                <li class="nav-item" onClick={() => setToggle("personal")}>
                  <a
                    class={`nav-link text-active-primary me-6 ${toggle === "personal" && "active"}`}
                    data-toggle="tab"
                  >
                    Machine Info.
                  </a>
                </li>
                <li class="nav-item" onClick={() => setToggle("clients")}>
                  <a
                    class={`nav-link text-active-primary me-6 ${toggle === "clients" && "active"}`}
                    data-toggle="tab"
                  >
                    Client
                  </a>
                </li>
                <li class="nav-item" onClick={() => setToggle("complaints")}>
                  <a
                    class={`nav-link text-active-primary me-6 ${toggle === "complaints" && "active"}`}
                    data-toggle="tab"
                  >
                    Complaints
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-xl-10 ">
          <div className="card">
            <div className="card-body p-4">
              <h2>Complaint Reports</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(205, 239, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Pending</div>
                        <div className="h1">{macCountData?.PendingComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(205, 239, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Assigned</div>
                        <div className="h1">{macCountData?.AssignComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(239, 236, 255)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Running</div>
                        <div className="h1">{macCountData?.RunningComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" role="button" style={{ backgroundColor: "rgb(214, 247, 215)" }}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>Completed</div>
                        <div className="h1">{macCountData?.CompletedComplaints}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card shadow">
        <div className="card-body pt-3">
          {toggle === "personal" ? (
            <div class="" id="">
              <div class="card-header cursor-pointer mx-0 px-0">
                <div class="card-title m-0">
                  <h3 class="fw-bolder m-0">Machine Details</h3>
                </div>
              </div>
              <div class="card-body pt-9 pb-0 mx-0 px-0">
                {/* <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Name</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{data?.machineName || "-"}</span>
                  </div>
                </div> */}
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Machine Number</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{data?.machineNo || "-"}</span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Serial Number</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{data?.serialNumber || "-"}</span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Machine Type</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {data?.machineType?.length > 0 ? data?.machineType[0]?.name : "-"}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Description</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{data?.description || "-"}</span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Buy Date</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {data?.buyDate?.length > 0 ? moment(data?.buyDate).format("DD-MM-YYYY") : "-"}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Expiry Date</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {data?.expDate?.length > 0 ? moment(data?.expDate).format("DD-MM-YYYY") : "-"}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Warranty Status</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {data?.machineStatus === 1 ? (
                        <>
                          <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
                            Warranty
                          </span>
                        </>
                      ) : data?.machineStatus === 2 ? (
                        <>
                          <span className="label font-weight-bold label-lg label-light-info label-inline">
                            AMC
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="label font-weight-bold label-lg label-light-danger label-inline">
                            Expired
                          </span>
                        </>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : toggle === "clients" ? (
            <div class="" id="">
              <div class="card-header cursor-pointer mx-0 px-0">
                <div class="card-title m-0">
                  <h3 class="fw-bolder m-0">Client Personal Details</h3>
                </div>
              </div>
              <div class="card-body pt-9 pb-0 mx-0 px-0">
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Name</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {clientData?.firstName || "-"} {clientData?.lastName || ""}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Email</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{clientData?.email || "-"}</span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Phone No.</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {clientData?.phoneNumber || "-"}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Company Name</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {clientData?.companyName || "-"}
                    </span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Address</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">{clientData?.address || "-"}</span>
                  </div>
                </div>
                <div class="row mb-7">
                  <label class="col-lg-4 fw-bold text-muted">Area Code</label>
                  <div class="col-lg-8">
                    <span class="fw-bolder fs-6 text-gray-800">
                      {clientData?.areaCode_data?.length > 0 ? clientData?.areaCode_data[0]?.code : "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : toggle === "complaints" ? (
            <>
              <BootstrapTable
                wrapperClasses="table-responsive"
                headerWrapperClasses="border-0"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="_id"
                data={complaintsData || []}
                columns={complaintColumns}
                noDataIndication={() => {
                  if (fetching) {
                    return (
                      <div className="text-center">
                        <img src={loader} className="h-100px" alt="loadingLogo" />
                      </div>
                    );
                  } else {
                    return <NoDataTable />;
                  }
                }}
              />
              <div className="d-flex justify-content-between  pt-10">
                <div className="my-2">
                  <Pagination
                    count={totalpage}
                    page={currentpage}
                    onChange={onPaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination_"
                  />
                </div>
                <div className="my-2">
                  <div className="d-flex align-items-center pagination-drpdown">
                    <select
                      className="form-control pagination-drpdown1 dropdownPage"
                      id="kt_datatable_search_status"
                      onChange={(e) => handleonchnagespagination(e)}
                      value={pagesize}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
