import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApiGet } from "../../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../../helpers/Toast";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import NoDataTable from "../../../../common/noDataTable";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";

export function MixedWidget1({ className }) {
  document.title = "Dashboard | Skyline";
  const history = useHistory();
  const [accountdata, setaccountdata] = useState({});
  const [accountdata1, setaccountdata1] = useState({});

  const [weekData, setWeekData] = useState([]);
  const [type, setType] = useState();


  const fetchData = async () => {
    await ApiGet("/count")
      .then((res) => {
        console.log("count", res);
        setaccountdata(res?.data?.data);
      })
      .catch(async (err) => {
        ErrorToast(err?.message);
      });
      await ApiGet("/count1")
      .then((res) => {
        console.log("count", res);
        setaccountdata1(res?.data?.data);
      })
      .catch(async (err) => {
        ErrorToast(err?.message);
      });
  };
  const columns = [
    {
      dataField: "image",
      text: "image",
      sort: true,
      formatter: (cell, row) => {
        return <div className="symbol symbol-50px symbol-light mr-4">
          <img src={cell[0] || ""} className="img-fluid object-cover" />
        </div>
      },
    },
    {
      dataField: "serialNumber",
      text: "serialNumber",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "machineNo",
      text: "machine no",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    // {
    //   dataField: "machineName",
    //   text: "machineName",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <div>{cell || "-"}</div>;
    //   },
    // },
    {
      dataField: "machineType_data",
      text: "machineType",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell[0]?.name || "-"}</div>;
      },
    },
    {
      dataField: "description",
      text: "description",
      sort: true,
      formatter: (cell, row) => {
        return <div className="text-truncate" style={{ width: "120px" }}>{cell || "-"}</div>;
      },
    },
    {
      dataField: "client_data",
      text: "Client Name",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell.length ? `${cell[0]?.firstName || "-"} ${cell[0]?.lastName || "-"}` : "N/A"}</div>;
      },
    },
    {
      dataField: "buyDate",
      text: "Buy Date",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      dataField: "expDate",
      text: "Expiry Date",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY")}</div>;
      },
    },
  ]
  // const getWeekData = async () => {
  //   await ApiGet("/topcomplaints")
  //     .then(res => setWeekData(res?.data?.data))
  //     .catch(err => ErrorToast(err?.message))
  // }
  useEffect(() => {
    // getWeekData();
    fetchData();
  }, []);

  useEffect(() => {
    const Id = JSON.parse(localStorage.getItem("userinfo"));
    console.log(Id);
    setType(Id?.response?.userType);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Dashboard</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Dashboard
            </span>
          </div>
        </div>
      </div>
      <div className="row">
       {type === 0? <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h2>Skyline Statistics</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#CDEFFF" }} role="button" onClick={() => history.push("/client")}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Clients</div>
                          <div className="h1">
                            {accountdata?.Clients || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#D6F7D7" }} role="button" onClick={() => history.push("/engineer")}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Engineers</div>
                          <div className="h1">
                            {accountdata?.Engs || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#EFECFF" }} role="button" onClick={() => history.push("/machine")}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Machines</div>
                          <div className="h1">
                            {accountdata?.Machines || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#FFD8E5" }} role="button" onClick={() => history.push("/complaint")}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Complaints</div>
                          <div className="h1">
                            {accountdata?.Complaint || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>:null}
        <div className="col-lg-6">
          <div className="card">
            <div className="card-body">
              <h2>Complaint Reports</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#FFF4CB" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Pending", number: 0 } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Pending</div>
                          <div className="h1">
                            {accountdata?.PendingComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#CDEFFF" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Assign", number: 1 } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Assigned</div>
                          <div className="h1">
                            {accountdata?.AssignComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#EFECFF" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Running", number: 2 } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Running</div>
                          <div className="h1">
                            {accountdata?.RunningComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#D6F7D7" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Complete", number: 3 } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Completed</div>
                          <div className="h1">
                            {accountdata?.CompletedComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {type === 0?<div className="col-lg-6 my-5">
          <div className="card">
            <div className="card-body">
              <h2>Machine Status</h2>
              <div className="row  gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#D6F7D7" }} role="button"
                    onClick={() => history.push({ pathname: "/machine", state: { name: "Warranty", value: 1 } })}
                  >
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Warranty</div>
                          <div className="h1">
                            {accountdata?.WarrantyMachine || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#EFECFF" }} role="button"
                    onClick={() => history.push({ pathname: "/machine", state: { name: "AMC", value: 2 } })}
                  >
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>AMC</div>
                          <div className="h1">
                            {accountdata?.AMCMachine || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/CMD.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#FFD8E5" }} role="button"
                    onClick={() => history.push({ pathname: "/machine", state: { name: "Out of Warranty", value: 3 } })}
                  >
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Expired</div>
                          <div className="h1">
                            {accountdata?.ExpireMachine || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>:null}
        <div className="col-lg-6 my-5">
          <div className="card">
            <div className="card-body">
              <h2>Today Complaint Reports</h2>
              <div className="row gy-5 gx-10 mt-1">
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#FFF4CB" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Pending", number: 0,today: true } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Pending</div>
                          <div className="h1">
                            {accountdata?.todayPendingComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#CDEFFF" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Assign", number: 1 ,today: true} })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Assigned</div>
                          <div className="h1">
                            {accountdata?.todayAssignComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Flag.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#EFECFF" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Running", number: 2 ,today: true} })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Running</div>
                          <div className="h1">
                            {accountdata?.todayRunningComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#D6F7D7" }} role="button" onClick={() => history.push({ pathname: "/complaint", state: { name: "Complete", number: 3,today: true } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Completed</div>
                          <div className="h1">
                            {accountdata?.todayCompletedComplaints || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                          <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="card py-3 rounded-20px" style={{ backgroundColor: "#FFD8E5" }} role="button" onClick={() => history.push({ pathname: "/alert", state: { today: true } })}>
                    <div className="p-5">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="div">
                          <div>Alert</div>
                          <div className="h1">
                            {accountdata1?.Alerttoday || "0"}
                          </div>
                        </div>
                        <div className="bg-white p-3 rounded-lg shadow">
                        <span className="svg-icon svg-icon-2hx menu-icon text-dark">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <div className="row my-10 gy-5">
        <div className="col-md-12">
          <div className="card p-5">
            <h1>Top Complaint Machines</h1>
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={weekData || []}
              columns={columns}
              noDataIndication={() => { return (<><div className="text-center">No Records Found</div></>) }}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="card p-5">
            <div className="text-center h1">Coming Soon...</div>
          </div>
        </div>
      </div> */}
    </>
  );
}
