import React, { Suspense, useEffect, useState } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import firebase from "./firebase";
import { DashboardPage } from "./Dashboard/DashboardPage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import AreaCode from "./pages/AreaCode/AreaCode";
import Client from "./pages/Client/Client";
import ClientDetails from "./pages/Client/ClientDetails";
import Complaint from "./pages/Complaint/Complaint";
import ComplaintDetails from "./pages/Complaint/ComplaintDetails";
import Engineer from "./pages/Engineer/Engineer";
import EngineerDetails from "./pages/Engineer/EngineerDetails";
import IssueType from "./pages/IssueType/IssueType";
import Machine from "./pages/Machine/Machine";
import MachineDetails from "./pages/Machine/MachineDetails";
import MachineType from "./pages/MachineType/MachineType";
import Map from "./pages/Map/Map";
import TrashClient from "./pages/Trash/TrashClient";
import TrashComplaint from "./pages/Trash/TrashComplaint";
import TrashEngineer from "./pages/Trash/TrashEngineer";
import TrashMachine from "./pages/Trash/TrashMachine";
import Alert from "./pages/Alert/Alert";
import { toast } from "react-hot-toast";
import SquerPart from "./pages/SqurePart/SquerPart";
import SubAdmin from "./pages/SubAdmin/SubAdmin";

export default function BasePage() {
  const history = useHistory();
  const [type, setType] = useState();

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging.onMessage((data) => {
        console.log("=======", data);
        setTimeout(() => {
          if (data.data.type == "4") {
            toast.success(data.notification.body);
            console.log(window.location.pathname);
            if (window.location.pathname == "/complaint") {
              history.push("/complaint-details/" + data.data.complaintId);
            } else {
              history.push("/complaint");
            }
          }
          if (data.data.type == "5") {
            toast.error(data.notification.body);
          }
        }, 2000);
      });
    }

    // messaging.requestPermission().then(()=>{
    //   return messaging.getToken()
    // }).then(token=>{
    //   console.log('Token :' , token)
    // }).catch(()=>{
    //   console.log("error")
    // })
  }, []);

  useEffect(() => {
    const Id = JSON.parse(localStorage.getItem("userinfo"));
    console.log(Id);
    setType(Id?.response?.userType);
  }, []);

  if (type === 1) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/engineer" component={Engineer} />
          <ContentRoute path="/engineer-details/:id" component={EngineerDetails} />
          <ContentRoute path="/complaint" component={Complaint} />
          <ContentRoute path="/complaint-details/:id" component={ComplaintDetails} />
          <ContentRoute path="/alert" component={Alert} />

          <ContentRoute path="/error/error-v1" component={ErrorsPage} /> 
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/engineer" component={Engineer} />
          <ContentRoute path="/engineer-details/:id" component={EngineerDetails} />
          <ContentRoute path="/complaint" component={Complaint} />
          <ContentRoute path="/complaint-details/:id" component={ComplaintDetails} />

          <ContentRoute path="/client" component={Client} />
          <ContentRoute path="/client-details/:id" component={ClientDetails} />
          <ContentRoute path="/machine" component={Machine} />
          <ContentRoute path="/machine-details/:id" component={MachineDetails} />
          <ContentRoute path="/subadmin" component={SubAdmin} />
          <ContentRoute path="/typeMachine" component={MachineType} />
          <ContentRoute path="/issueType" component={IssueType} />

          <ContentRoute path="/areaCode" component={AreaCode} />
          {/* <ContentRoute path="/sparePart" component={SquerPart} /> */}
          <ContentRoute path="/map" component={Map} />
          <ContentRoute path="/alert" component={Alert} />

          <ContentRoute path="/trash-client" component={TrashClient} />
          <ContentRoute path="/trash-engineer" component={TrashEngineer} />
          <ContentRoute path="/trash-machine" component={TrashMachine} />
          <ContentRoute path="/trash-complaint" component={TrashComplaint} />

          <ContentRoute path="/error/error-v1" component={ErrorsPage} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
}
