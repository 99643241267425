import firebase from "firebase"

const config = {
    apiKey: "AIzaSyBHc3Pnl9leMvGCHGeG4siJRUwTmbtrjAk",
    authDomain: "fir-app-bbf29.firebaseapp.com",
    projectId: "fir-app-bbf29",
    storageBucket: "fir-app-bbf29.appspot.com",
    messagingSenderId: "760157688860",
    appId: "1:760157688860:web:9a51a340e27d8c6aa6fbd9",
    measurementId: "G-69NKRCB1MF"
}
firebase.initializeApp(config)

export default firebase