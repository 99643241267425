import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import Select from 'react-select';
import {
	ApiDelete,
	ApiGet,
	ApiPost,
	ApiPut,
	ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Dropdown, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import QRCode from 'qrcode.react';
import { ImSpinner9 } from 'react-icons/im'
import DateRangePicker from "react-bootstrap-daterangepicker";
import { MdCancel } from 'react-icons/md'
import loader from '../../../media/loaderSkyline.gif'
import { AiFillEye } from "react-icons/ai";
import Pdf from "react-to-pdf";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
const ref = React.createRef();


export default function Machine() {
	const history = useHistory();
	const location = useLocation();
	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [qrModal, setQrModal] = useState(false);
	const [totalpage, settotalpage] = useState(0);
	const [currentpage, setcurrentpage] = useState(1);
	const [pagesize, setpagesize] = useState(10);
	const [searching, setsearching] = useState("");
	const [fLoading, setFLoading] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const [editOption, setEditOption] = useState({});
	const [error, setError] = useState({});
	const [flag, setFlag] = useState(false);
	const [machineType, setMachineType] = useState([]);
	const [clients, setClients] = useState([]);
	const [qrData, setQrData] = useState({});
	const [playMachineStatus, setPlayMachineStatus] = useState({ name: "By Warranty", value: null });
	// const [playMachineStatus, setPlayMachineStatus] = useState(null);
	const [playMachineType, setPlayMachineType] = useState({ name: "By MachineType", id: "" });
	const [playClientId, setPlayClientId] = useState({ name: "By Client", id: "" });
	const [playStartDate, setPlayStartDate] = useState("");
	const [playEndDate, setPlayEndDate] = useState("");
	const [showImage, setShowImage] = useState([]);
	const [dateType, setDateType] = useState("buy");
	const [data1, setdata1] = useState("");
	const [newData, setNewData] = useState({
		serialNumber: "",
		machineType: "",
		description: "",
		buyDate: "",
		expDate: "",
		clientId: "",
		machineStatus: null
	});
	console.log("newData", newData);
	const columns = [
		// {
		// 	dataField: "image",
		// 	text: "image",
		// 	sort: true,
		// 	formatter: (cell, row) => {
		// 		return <div className="symbol symbol-50px symbol-light mr-4">
		// 			<img src={cell[0] || "https://res.cloudinary.com/deh7sqhvn/image/upload/v1675313541/mg21028134.100-1_300_dngzya.webp"}
		// 				onError={({ currentTarget }) => {
		// 					currentTarget.onerror = null; // prevents looping
		// 					currentTarget.src = "https://res.cloudinary.com/deh7sqhvn/image/upload/v1675313541/mg21028134.100-1_300_dngzya.webp";
		// 				}}
		// 				className="img-fluid object-cover" loading="lazy" />
		// 		</div>
		// 	},
		// },
		{
			dataField: "serialNumber",
			text: "serial Number",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		{
			dataField: "machineNo",
			text: "machine no",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		{
			dataField: "machineType_data",
			text: "machine Type",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell[0]?.name || "-"}</div>;
			},
		},
		{
			dataField: "client_data",
			text: "Client Name",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell.length ? `${cell[0]?.firstName || "-"} ${cell[0]?.lastName || "-"}` : "N/A"}</div>;
			},
		},
		{
			dataField: "completedCom",
			text: "completed Complaints",
			sort: true,
			style: { width: '10px' },
			formatter: (cell, row) => {
				return <div>{cell || "0"}</div>;
			},
		},
		{
			dataField: "completedCom",
			text: "not completed Complaints",
			style: { width: '10px' },
			sort: true,
			formatter: (cell, row) => {
				return <div>{row?.pendingCom + row?.assignCom + row?.runningCom}</div>;
			},
		},
		{
			dataField: "buyDate",
			text: "Buy Date",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell && moment(cell).format("DD-MM-YYYY")}</div>;
			},
		},
		{
			dataField: "expDate",
			text: "Expiry Date",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell && moment(cell).format("DD-MM-YYYY")}</div>;
			},
		},
		{
			dataField: "machineStatus",
			text: "status",
			sort: true,
			headerStyle: { width: "100px" },
			formatter: (cell, row) => {
				if (cell === 1) {
					return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">Warranty</span>
				}
				else if (cell === 2) {
					return <span className="label font-weight-bold label-lg label-light-info label-inline">AMC</span>
				} else if (cell === 3) {
					return <span className="label font-weight-bold label-lg label-light-danger label-inline">Expired</span>
				}
			},
		},
		{
			dataField: "action",
			text: "Action",
			sort: true,
			headerStyle: {
				textAlign: "center"
			},
			formatter: (cell, row) => {
				return (
					<div style={{ width: "161px" }}>
						<a
							title="View User"
							className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
							onClick={() => history.push("/machine-details/" + row?._id)}
						>
							<span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
								<AiFillEye className="" />
							</span>
						</a>
						<a
							title="Edit customer"
							className="btn btn-icon btn-light btn-hover-primary btn-sm"
							onClick={() => editBtnClick(row)}
						>
							<span className="svg-icon svg-icon-md svg-icon-primary">
								<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Write.svg"
									)}
									title="Edit customer"
								/>
							</span>
						</a>

						<a
							title="Delete customer"
							className="btn btn-icon btn-light btn-hover-danger btn-sm mx-2"
							onClick={() => {
								Swal.fire({
									text: `Are you sure you want to Delete ?`,
									icon: "warning",
									showCancelButton: true,
									showConfirmButton: true,
									confirmButtonText: `Yes, Delete`,
									confirmButtonColor: "#D72852",
									cancelButtonColor: "transparent",
									cancelButtonText: "No, Cancel",
								}).then((res) => {
									if (res.isConfirmed) {
										onDelete(row?._id);
									}
								});
							}}
						>
							<span className="svg-icon svg-icon-md svg-icon-danger">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title="Delete customer"
								/>
							</span>
						</a>
						<a
							title="QR Code"
							className="btn btn-icon btn-light btn-hover-primary btn-sm"
							onClick={() => { handleQrClick(row) }}
						>
							<span className="svg-icon svg-icon-md svg-icon-primary">
								<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Shopping/Barcode-read.svg"
									)} title="QR Code" />
							</span>
						</a>
					</div>
				);
			},
		},
	];
	const validate = (values) => {
		const err = {};
		if (!values.serialNumber) {
			err.serialNumber = "Serial number is required";
		}
		if (!values.machineType) {
			err.machineType = "Machine type is required";
		}
		// if (!values.machineName) {
		// 	err.machineName = "Machine name is required";
		// }
		if (!values.machineStatus) {
			err.machineStatus = "Machine Status is required";
		}
		// if (!values.description) {
		// 	err.description = "Description is required";
		// }
		if (!values.buyDate) {
			err.buyDate = "Buy date is required";
		}
		if (!values.expDate) {
			err.expDate = "Expiry date is required";
		}
		setError(err);
		if (Object.keys(err).length === 0) {
			return false;
		} else {
			return true;
		}
	};

	const handleChange = (e) => {
		const { value, name } = e.target;
		setNewData({ ...newData, [name]: value.trimStart() });
	};

	const editBtnClick = (row) => {
		setIsEdit(true);
		setModal(true);
		setError({});
		setShowImage(row?.image)
		let clientId = {}
		if (row?.client_data[0]?.firstName || row?.client_data[0]?.lastName) {
			clientId.value = row?.clientId
			clientId.label = `${row?.client_data[0]?.firstName} ${row?.client_data[0]?.lastName}`
		}
		if (Object.keys(clientId).length === 0) {
			setEditOption(false)
		} else {
			setEditOption(clientId)
		}
		setNewData({
			id: row?._id,
			serialNumber: row?.serialNumber,
			machineType: row?.machineType_data[0]?._id || "",
			machineStatus: row?.machineStatus,
			description: row?.description,
			buyDate: moment(row?.buyDate).format("YYYY-MM-DD"),
			expDate: moment(row?.expDate).format("YYYY-MM-DD"),
			clientId: row?.clientId
		});
	};
	const handleQrClick = (row) => {
		setQrModal(true);
		console.log("row", row);
		setNewData({
			...newData,
			serialNumber: row?.serialNumber,
			machineNo: row?.machineNo,
		})
		let object = {
			Machine_Id: row?._id,
			machine_Number: row?.machineNo,
			machineType: row?.machineType[0]?._id,
		}
		console.log("object", object);
		setdata1(row?.machineNo)
		const encodedString = new Buffer(JSON.stringify(object)).toString('base64')
		setQrData(encodedString)
	}


	const onDelete = async (Id) => {
		await ApiDelete(`/machine/${Id}`)
			.then(() => {
				Swal.fire({
					text: "Machine Deleted Successfully!!!",
					icon: "success",
					confirmButtonText: "Ok, Got it!",
					confirmButtonColor: "#338DE6",
				});
				fetchData(
					currentpage,
					pagesize,
					searching,
					playMachineStatus?.value,
					playMachineType?.id,
					playClientId?.id,
					playStartDate,
					playEndDate,
					dateType
				);
			})
			.catch((err) => ErrorToast(err?.message));
	};
	const onUpdate = async () => {
		if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
		if ((newData?.expDate >= moment().format("YYYY-MM-DD")) && newData?.machineStatus === 3) {
			ErrorToast("Machine Status invalid, please select valid Expiry Date")
			return;
		}
		setFLoading(true)
		let body = {
			id: newData?.id,
			serialNumber: newData?.serialNumber,
			machineType: newData?.machineType,
			machineStatus: newData?.machineStatus,
			description: newData?.description,
			buyDate: new Date(newData?.buyDate),
			expDate: new Date(newData?.expDate),
			clientId: newData?.clientId || null,
		};

		if (showImage.length !== 0) {
			body["image"] = showImage;
		} else { body["image"] = null }
		await ApiPut("/machine", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				setFLoading(false)
				fetchData(
					currentpage,
					pagesize,
					searching,
					playMachineStatus?.value,
					playMachineType?.id,
					playClientId?.id,
					playStartDate,
					playEndDate,
					dateType
				);
			})
			.catch((err) => { ErrorToast(err?.message); setFLoading(false); });
	};
	const handleSubmit = async () => {
		if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
		if ((newData?.expDate >= moment().format("YYYY-MM-DD")) && newData?.machineStatus === 3) {
			ErrorToast("Machine Status invalid, please select valid Expiry Date")
			return;
		}
		setFLoading(true)
		let body = {
			serialNumber: newData?.serialNumber,
			machineType: newData?.machineType,
			machineStatus: newData?.machineStatus,
			description: newData?.description,
			buyDate: newData?.buyDate,
			expDate: newData?.expDate,
		};
		if (newData?.clientId) {
			body["clientId"] = newData?.clientId
		} else {
			body["clientId"] = null;
		}
		if (showImage.length !== 0) {
			body["image"] = showImage;
		}
		await ApiPost("/machine", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				setFLoading(false);
				fetchData(
					currentpage,
					pagesize,
					searching,
					playMachineStatus?.value,
					playMachineType?.id,
					playClientId?.id,
					playStartDate,
					playEndDate,
					dateType
				);
			})
			.catch((err) => { ErrorToast(err?.message); setFLoading(false); });
	};
	const handleonchnagespagination = (e) => {
		setpagesize(e.target.value);
		setcurrentpage(1);
		fetchData(
			1,
			parseInt(e.target.value),
			searching,
			playMachineStatus?.value,
			playMachineType?.id,
			playClientId?.id,
			playStartDate,
			playEndDate,
			dateType
		);
	};
	const onPaginationChange = (e, i) => {
		setcurrentpage(i);
		fetchData(
			i,
			pagesize,
			searching,
			playMachineStatus?.value,
			playMachineType?.id,
			playClientId?.id,
			playStartDate,
			playEndDate,
			dateType
		);
	};
	const imageChange = async (e) => {
		e.persist()
		setFLoading(true)
		let images = [...showImage]
		for (let i = 0; i < e.target.files.length; i++) {
			let file = e.target.files[i];
			let fileURL = URL.createObjectURL(file);
			file.fileURL = fileURL;
			let formData = new FormData();
			formData.append("image", file);
			console.log("file", formData);
			await ApiUpload("admin/image", formData)
				.then((res) => {
					images.push(res?.data?.data)
				})
				.catch((err) => console.log("res_blob", err));
		}
		setShowImage(images)
		setFLoading(false);
	};
	const handlesearch = (e) => {
		setsearching(e.target.value);
		setcurrentpage(1)
		fetchData(
			1,
			pagesize,
			e.target.value,
			playMachineStatus?.value,
			playMachineType?.id,
			playClientId?.id,
			playStartDate,
			playEndDate,
			dateType
		);
	};
	const downloadQR = () => {
		const canvas = document.getElementById("QrCodeId");
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `${newData?.machineId || "QrCode"}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};
	const handleOnChangeWarranty = (e) => {
		setcurrentpage(1);
		let a = JSON.parse(e)
		setPlayMachineStatus(a);
		fetchData(
			1,
			pagesize,
			searching,
			a?.value,
			playMachineType?.id,
			playClientId?.id,
			playStartDate,
			playEndDate,
			dateType
		);
	}
	const handleOnChangeMachineType = (e) => {
		setcurrentpage(1);
		let a = JSON.parse(e)
		setPlayMachineType(a);
		fetchData(
			1,
			pagesize,
			searching,
			playMachineStatus?.value,
			a?.id,
			playClientId?.id,
			playStartDate,
			playEndDate,
			dateType
		);
	}
	const handleOnChangeClient = (e) => {
		setcurrentpage(1);
		setPlayClientId({ name: e?.label, id: e?.value });
		console.log('a :>> ', e);
		fetchData(
			1,
			pagesize,
			searching,
			playMachineStatus?.value,
			playMachineType?.id,
			e?.value,
			playStartDate,
			playEndDate,
			dateType,
		);
	}
	const onApply = (event, picker) => {
		setcurrentpage(1);
		let sDate = new Date(new Date(picker?.startDate?._d).setDate(new Date(picker?.startDate?._d).getDate() + 1))
		setPlayStartDate(sDate)
		setPlayEndDate(new Date(picker?.endDate?._d));
		fetchData(
			1,
			pagesize,
			searching,
			playMachineStatus?.value,
			playMachineType?.id,
			playClientId?.id,
			sDate,
			new Date(picker?.endDate?._d),
			dateType
		);
	};
	const onCancel = () => {
		setcurrentpage(1);
		fetchData(
			1,
			pagesize,
			searching,
			playMachineStatus?.value,
			playMachineType?.id,
			playClientId?.id,
			null,
			null,
			dateType
		);
	}
	const getClients = async () => {
		await ApiGet("/Client")
			.then(async (res) => {
				console.log("/Client", res?.data?.data);
				setClients(res?.data?.data)
				const arr = []
				const a = await res?.data?.data?.map((v, i) => {
					arr.push({ value: v?._id, label: `${v?.firstName} ${v?.lastName}` })
				})
				setOptions(arr)
			})
			.catch(err => ErrorToast(err?.message))
	}
	const getMachineType = async () => {
		await ApiGet("/machineType")
			.then(res => {
				console.log("/machineType", res?.data?.data);
				setMachineType(res?.data?.data)
			})
			.catch(err => ErrorToast(err?.message))
	}
	const fetchData = async (page, limit, search, machineStatus, machineType, clientId, startDate, endDate, datetype) => {
		let body = { page, limit, search, machineStatus, machineType, clientId };
		if (datetype === "buy") { body["startDate"] = startDate; body["endDate"] = endDate }
		if (datetype === "expiry") { body["exp_startDate"] = startDate; body["exp_endDate"] = endDate; }
		setFetching(true)
		await ApiPost("/getmachine", body)
			.then((res) => {
				console.log("/getmachine", res?.data?.data);
				setData(res?.data?.data?.response);
				settotalpage(res?.data?.data?.state?.page_limit);
				setFetching(false);
			})
			.catch((err) => { ErrorToast(err?.message); setFetching(false) });
	};
	useEffect(() => {
		let newStatus = playMachineStatus;
		if (location.state) {
			newStatus = location.state;
			setPlayMachineStatus(location.state);
		}
		fetchData(
			currentpage,
			pagesize,
			searching,
			newStatus?.value,
			playMachineType?.id,
			playClientId?.id,
			playStartDate,
			playEndDate,
			dateType
		);
		getMachineType();
		getClients()
	}, []);

	console.log('newData :>> ', newData);

	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">Machine</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							Machine
						</span>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<div className="card card-custom">
					<div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
						<div class="card-title" style={{ width: "85%" }}>
							<div class="row align-items-center w-100">
								<div className="col-md-3 my-2">
									<OverlayTrigger
										placement="top"
										overlay={<Tooltip id="button-tooltip-2">Search by Serial Number, Machine Number and Machine Name</Tooltip>}
									>
										<div class="input-icon">
											<input
												type="text"
												class="form-control bg-light"
												name="searchText"
												placeholder="Search ..."
												value={searching}
												onChange={(e) => handlesearch(e)}
												id="kt_datatable_search_query"
											/>
											<span>
												<i class="flaticon2-search-1 text-muted"></i>
											</span>
										</div>
									</OverlayTrigger>
								</div>
								<div className="col-md-3 my-2">
									<Dropdown
										onSelect={handleOnChangeWarranty}
									>
										<Dropdown.Toggle
											id="dropdown-basic"
											className="text-capitalize"
										>
											{playMachineStatus?.name}
										</Dropdown.Toggle>
										<Dropdown.Menu style={{ minWidth: "100%" }}>
											<Dropdown.Item eventKey={JSON.stringify({ name: "Filter Warranty", value: null })}>
												None of these
											</Dropdown.Item>
											<Dropdown.Item eventKey={JSON.stringify({ name: "Warranty", value: 1 })}>
												Warranty
											</Dropdown.Item>
											<Dropdown.Item eventKey={JSON.stringify({ name: "AMC", value: 2 })}>
												AMC
											</Dropdown.Item>
											<Dropdown.Item eventKey={JSON.stringify({ name: "Out of Warranty", value: 3 })}>
												Out of Warranty
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
								<div className="col-md-3 my-2">
									<Dropdown
										onSelect={handleOnChangeMachineType}
									>
										<Dropdown.Toggle
											id="dropdown-basic"
											className="text-capitalize w-100 text-truncate"
										>
											{playMachineType?.name}
										</Dropdown.Toggle>
										<Dropdown.Menu style={{ minWidth: "100%" }}>
											<Dropdown.Item eventKey={JSON.stringify({ name: "By MachineType", id: "" })}>
												None of these
											</Dropdown.Item>
											{machineType?.map((v) => {
												return <Dropdown.Item key={v?._id} eventKey={JSON.stringify({ name: v?.name, id: v?._id })}>
													{v?.name}
												</Dropdown.Item>
											})}
										</Dropdown.Menu>
									</Dropdown>
								</div>

								<div className="col-md-3 my-2 pe-0 d-flex">
									<select name="buyExpDate" id="" className="buyExpDateSelect"
										onChange={(e) => {
											setDateType(e.target.value);
											fetchData(
												currentpage,
												pagesize,
												searching,
												playMachineStatus?.value,
												playMachineType?.id,
												playClientId?.id,
												playStartDate,
												playEndDate,
												e.target.value
											);
										}}>
										<option value="buy">Buy Date</option>
										<option value="expiry">Expiry Date</option>
									</select>
									<DateRangePicker
										onApply={onApply}
										onCancel={onCancel}
									>
										<input type="text" className="form-control buyExpInput" autoUpdateInput={false} />
									</DateRangePicker>
								</div>
							</div>

						</div>

						<div className="card-toolbar">
							<button
								className="btn btn-color font-weight-bolder btn-sm"
								onClick={() => {
									setIsEdit(false);
									setFlag(false);
									setError({});
									setModal(true);
									setShowImage([])
									setNewData({
										serialNumber: "",
										machineType: "",
										description: "",
										buyDate: "",
										expDate: "",
										clientId: "",
										machineStatus: ""
									});
								}}
							>
								Add Machine
							</button>
						</div>
					</div>
					<div className="col-md-3 my-2">
						{/* <Dropdown
							onSelect={handleOnChangeClient}
						>
							<Dropdown.Toggle
								id="dropdown-basic"
								className="text-capitalize"
							>
								{playClientId?.name}
							</Dropdown.Toggle>
							<Dropdown.Menu style={{ minWidth: "100%" }}>
								<Dropdown.Item eventKey={JSON.stringify({ name: "By Client", id: "" })}>
									None of these
								</Dropdown.Item>
								{clients?.map((v) => {
									return <Dropdown.Item key={v?._id} eventKey={JSON.stringify({ name: `${v?.firstName} ${v?.lastName}`, id: v?._id })}>
										{v?.firstName || ""} {v?.lastName || ""}
									</Dropdown.Item>
								})}
							</Dropdown.Menu>
						</Dropdown> */}

						<Form.Group className="col-md-12 mb-0">
							<Select
								className="basic-single text-sm-start"
								defaultValue={isEdit && editOption}
								onChange={(e) => {
									// setNewData({ ...newData, clientId: e?.value });
									handleOnChangeClient(e)
								}}
								placeholder="By Client"
								isClearable={true}
								isRtl={false}
								isSearchable={true}
								name="color"
								options={options}
								style={{
									control: (provided, state) => ({
										...provided,
										border: state.isActive ? '2px solid red' : provided.border,

									}),

								}}
							/>
						</Form.Group>
					</div>
					<div className="card-body mb-5 pt-1">
						<BootstrapTable
							wrapperClasses="table-responsive"
							headerWrapperClasses="border-0"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							data={data}
							columns={columns}
							noDataIndication={() => {
								if (fetching) {
									return <div className="text-center"><img src={loader} className="h-100px" alt="loadingLogo" /></div>
								} else {
									return <NoDataTable />
								}
							}}
						/>
						<div className="d-flex justify-content-between  pt-10">
							<div className="my-2">
								<Pagination
									count={totalpage}
									page={currentpage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div className="my-2">
								<div className="d-flex align-items-center pagination-drpdown">
									<select
										className="form-control pagination-drpdown1 dropdownPage"
										id="kt_datatable_search_status"
										onChange={(e) => handleonchnagespagination(e)}
										value={pagesize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

			<Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered>
				<Modal.Header closeButton>
					<Modal.Title>{isEdit ? "Update" : "Add New"} Machine</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<Form.Group className="col-md-12">
							<Form.Label>Serial Number <span className="text-danger">*</span></Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Serial Number"
								name="serialNumber"
								onChange={handleChange}
								value={newData?.serialNumber}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.serialNumber &&
								<span className="errorInput">
									{error["serialNumber"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>Machine Type <span className="text-danger">*</span></Form.Label>
							<select
								className="form-select"
								name="machineType"
								onChange={handleChange}
								value={newData?.machineType}
								onBlur={() => {
									if (flag) { validate(newData) }
								}}
								role="button"
							>
								<option value="" disabled>Select Machine Type</option>
								{machineType?.map((v, i) => {
									return <option value={v?._id} key={i}>{v?.name}</option>
								})}
							</select>
							{error?.machineType &&
								<span className="errorInput">
									{error["machineType"]}
								</span>}
						</Form.Group>
						{/* <Form.Group className="col-md-12">
							<Form.Label className="w-100">Image</Form.Label>
							<input name="image" type="file" id="image" className="form-control" onChange={imageChange} multiple />
							{showImage.length > 0 && showImage.map((v, i) => {
								return <div className="symbol symbol-75 position-relative mt-3 ml-5" key={i}>
									<img src={v} className="img-fluid object-cover rounded" />
									<span className="imageRemovoIconSpan"
										onClick={() => {
											let a = [...showImage]
											a.splice(i, 1)
											console.log("asd", a);
											setShowImage(a)
											if (a.length === 0) {
												document.getElementById("image").value = null;
											}
										}}>
										<MdCancel color="red" fontSize={20} />
									</span>
								</div>
							})
							}
						</Form.Group> */}
						<Form.Group className="col-md-6">
							<Form.Label>Buy Date <span className="text-danger">*</span></Form.Label>
							<Form.Control
								type="date"
								name="buyDate"
								onChange={handleChange}
								value={newData?.buyDate}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.buyDate &&
								<span className="errorInput">
									{error["buyDate"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-6">
							<Form.Label>Expiry Date <span className="text-danger">*</span></Form.Label>
							<Form.Control
								type="date"
								name="expDate"
								onChange={(e) => {
									if (e.target.value <= moment().format("YYYY-MM-DD")) {
										setNewData({ ...newData, machineStatus: 3, expDate: e.target.value })
									} else {
										console.log("kjkhgfd");

										setNewData({ ...newData, machineStatus: 0, expDate: e.target.value })
									}
								}}
								value={newData?.expDate}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.expDate &&
								<span className="errorInput">
									{error["expDate"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>Machine Status <span className="text-danger">*</span></Form.Label>
							<div className="d-md-flex">
								<label class="radio col-md-4 ps-0">
									<input
										type="radio"
										name="radios1"
										checked={newData?.machineStatus === 1}
										onClick={() => setNewData({ ...newData, machineStatus: 1 })}
										onBlur={() => { if (flag) { validate(newData) } }}
									/>
									<span></span>
									<div className="ms-3">Warranty</div>
								</label>
								<label class="radio col-md-4">
									<input
										type="radio"
										name="radios1"
										checked={newData?.machineStatus === 2}
										onClick={() => setNewData({ ...newData, machineStatus: 2 })}
										onBlur={() => { if (flag) { validate(newData) } }}
									/>
									<span></span>
									<div className="ms-3">AMC</div>
								</label>
								<label class="radio col-md-4">
									<input
										type="radio"
										name="radios1"
										checked={newData?.machineStatus === 3}
										onClick={() => setNewData({ ...newData, machineStatus: 3 })}
										onBlur={() => { if (flag) { validate(newData) } }}
									/>
									<span></span>
									<div className="ms-3">Expire</div>
								</label>
							</div>
							{error?.machineStatus &&
								<span className="errorInput">
									{error["machineStatus"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>Description</Form.Label>
							<Form.Control
								as="textarea"
								placeholder="Enter Description"
								name="description"
								onChange={handleChange}
								value={newData?.description}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.description &&
								<span className="errorInput">
									{error["description"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>Client</Form.Label>
							{/* <select
								className="form-select"
								name="clientId"
								onChange={handleChange}
								value={newData?.clientId}
								role="button"
							>
								<option value="">Not Selected</option>
								{clients?.map((v, i) => {
									return <option value={v?._id} key={i}>{v?.firstName} {v?.lastName || ""}</option>
								})}
							</select> */}
							<Select
								className="basic-single"
								defaultValue={isEdit && editOption}
								onChange={(e) => setNewData({ ...newData, clientId: e?.value })}
								placeholder="Select Client"
								isClearable={true}
								isRtl={false}
								isSearchable={true}
								name="color"
								options={options}
								style={{
									control: (provided, state) => ({
										...provided,
										border: state.isActive ? '2px solid red' : provided.border,
									}),
								}}
							/>
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{isEdit ? (
						<button className="btn btn-color" onClick={() => !fLoading && onUpdate()} disabled={fLoading}>
							Update Changes {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
						</button>
					) : (
						<button className="btn btn-color" onClick={() => !fLoading && handleSubmit()} disabled={fLoading}>
							Submit {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
						</button>
					)}
				</Modal.Footer>
			</Modal>
			<Modal fullscreen="true" show={qrModal} onHide={() => setQrModal(false)} centered>
				<Modal.Header closeButton className="pb-0"><h2>{newData?.machineNo} - {newData?.serialNumber}</h2></Modal.Header>
				<div className="text-center mb-4">
					<Pdf targetRef={ref} filename={`${data1}`} x={0} y={0} scale={1}>
						{({ toPdf }) => <button className="btn btn-color btn-sm" onClick={toPdf}> Download QR </button>}
					</Pdf>
					{/* <Pdf targetRef={ref} filename="code-example.pdf">
					<button className="btn btn-color btn-sm" onClick={downloadQR}> Download QR </button>
					</Pdf> */}

				</div>
				<div ref={ref}>
					<QRCode
						id="QrCodeId"
						value={JSON.stringify(qrData)}
						className=" qr-code"
						size={800}
						level={"L"}
						includeMargin={true}
					/>
					<div style={{ textAlign: "center" }}>
						<div style={{ fontSize: "6.50rem", fontWeight: "bold" }}>M.No- {newData?.machineNo}</div>
						<div style={{ fontSize: "6.00rem", fontWeight: "bold" }}>{newData?.serialNumber}</div>
					</div>
				</div>

			</Modal>
		</>
	);
}
