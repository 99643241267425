import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
	ApiDelete,
	ApiGet,
	ApiPost,
	ApiPut,
	ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { ImSpinner9 } from 'react-icons/im'
import { Dropdown, Form, Modal } from "react-bootstrap";
import loader from '../../../media/loaderSkyline.gif'

export default function IssueType() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [totalpage, settotalpage] = useState(0);
	const [currentpage, setcurrentpage] = useState(1);
	const [pagesize, setpagesize] = useState(10);
	const [searching, setsearching] = useState("");
	const [fLoading, setFLoading] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [error, setError] = useState({});
	const [flag, setFlag] = useState(false);


	const [newData, setNewData] = useState({
		name: "",
	});

	const columns = [
		{
			dataField: "name",
			text: "Issue Type",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		{
			dataField: "action",
			text: "Action",
			sort: true,
			formatter: (cell, row) => {
				return (
					<>
						<a
							title="Edit customer"
							className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
							onClick={() => editBtnClick(row)}
						>
							<span className="svg-icon svg-icon-md svg-icon-primary">
								<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Write.svg"
									)}
								/>
							</span>
						</a>

						<a
							title="Delete customer"
							className="btn btn-icon btn-light btn-hover-danger btn-sm"
							onClick={() => {
								Swal.fire({
									text: `Are you sure you want to Delete ?`,
									icon: "warning",
									showCancelButton: true,
									showConfirmButton: true,
									confirmButtonText: `Yes, Delete`,
									confirmButtonColor: "#D72852",
									cancelButtonColor: "transparent",
									cancelButtonText: "No, Cancel",
								}).then((res) => {
									if (res.isConfirmed) {
										onDelete(row?._id);
									}
								});
							}}
						>
							<span className="svg-icon svg-icon-md svg-icon-danger">
								<SVG
									src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
								/>
							</span>
						</a>
					</>
				);
			},
		},
	];
	const validate = (values) => {
		const err = {};
		if (!values.name) {
			err.name = "First name is required";
		}
		setError(err);
		if (Object.keys(err).length === 0) {
			return false;
		} else {
			return true;
		}
	};

	const handleChange = (e) => {
		const { value, name } = e.target;
		setNewData({ ...newData, [name]: value.trimStart() });
	};
	const editBtnClick = (row) => {
		setIsEdit(true);
		setModal(true);
		setError({});
		setNewData({
			id: row?._id,
			name: row?.name,
		});
	};
	const onDelete = async (Id) => {
		await ApiDelete(`/issueType/${Id}`)
			.then(() => {
				Swal.fire({
					text: "Issue Type Deleted Successfully!!!",
					icon: "success",
					confirmButtonText: "Ok, Got it!",
					confirmButtonColor: "#338DE6",
				});
				fetchData(currentpage, pagesize, searching);
			})
			.catch((err) => ErrorToast(err?.message));
	};
	const onUpdate = async () => {
		if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
		setFLoading(true);
		let body = {
			id: newData?.id,
			name: newData?.name.trim(),
		};
		console.log("body", body);
		await ApiPut("/issueType", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				setFLoading(false);
				fetchData(currentpage, pagesize, searching);
			})
			.catch((err) => { ErrorToast(err?.message); setFLoading(false); });
	};
	const handleSubmit = async () => {
		if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
		setFLoading(true);
		let body = {
			name: newData?.name.trim(),
		};
		await ApiPost("/issueType", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				setFLoading(false);
				fetchData(currentpage, pagesize, searching);
			})
			.catch((err) => { ErrorToast(err?.message); setFLoading(false); });
	};
	const handleonchnagespagination = (e) => {
		setpagesize(e.target.value);
		setcurrentpage(1);
		fetchData(1, parseInt(e.target.value), searching);
	};
	const onPaginationChange = (e, i) => {
		setcurrentpage(i);
		fetchData(i, pagesize, searching);
	};
	const handlesearch = (e) => {
		setcurrentpage(1);
		setsearching(e.target.value);
		fetchData(1, pagesize, e.target.value);
	};

	const fetchData = async (page, limit, search) => {
		let body = { page, limit, search };
		setFetching(true)
		await ApiPost("/getIssueType", body)
			.then((res) => {
				console.log("/getIssueType", res?.data?.data);
				setData(res?.data?.data?.response);
				settotalpage(res?.data?.data?.state?.page_limit);
			})
			.catch((err) => { ErrorToast(err?.message); setFetching(false) });
	};
	useEffect(() => {
		fetchData(currentpage, pagesize, searching);
	}, []);
	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">Issue Type</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							Issue Type
						</span>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<div className="card card-custom">
					<div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
						<div className="card-title ">
							{/* <h3 className="card-label">Video Playlist</h3> */}
							<div className="input-icon">
								<input
									type="text"
									className="form-control bg-light"
									name="searchText"
									placeholder="Search by Issue Type"
									value={searching}
									onChange={(e) => handlesearch(e)}
									id="kt_datatable_search_query"
								/>
								<span>
									<i className="flaticon2-search-1 text-muted"></i>
								</span>
							</div>
						</div>

						<div className="card-toolbar">
							<button
								className="btn btn-color font-weight-bolder btn-sm"
								onClick={() => {
									setIsEdit(false);
									setFlag(false);
									setError({});
									setModal(true);
									setNewData({
										firstName: "",
										lastName: "",
										address: "",
										phoneNumber: "",
										email: "",
										password: ""
									});
								}}
							>
								Add Issue Type
							</button>
						</div>
					</div>
					<div className="card-body mb-5">
						<BootstrapTable
							wrapperClasses="table-responsive"
							headerWrapperClasses="border-0"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							data={data}
							columns={columns}
							noDataIndication={() => {
								if (fetching) {
									return <div className="text-center"><img src={loader} className="h-100px" alt="loadingLogo" /></div>
								} else {
									return <NoDataTable />
								}
							}}
						/>
						<div className="d-flex justify-content-between  pt-10">
							<div className="my-2">
								<Pagination
									count={totalpage}
									page={currentpage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div className="my-2">
								<div className="d-flex align-items-center pagination-drpdown">
									<select
										className="form-control pagination-drpdown1 dropdownPage"
										id="kt_datatable_search_status"
										onChange={(e) => handleonchnagespagination(e)}
										value={pagesize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered>
				<Modal.Header closeButton>
					<Modal.Title>{isEdit ? "Update" : "Add New"} Issue Type</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<Form.Group className="col-md-12">
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Issue Type"
								label="name"
								id="name"
								required
								name="name"
								onChange={handleChange}
								value={newData?.name}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.name &&
								<span className="errorInput">
									{error["name"]}
								</span>}
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{isEdit ? (
						<button className="btn btn-color" onClick={onUpdate} disabled={fLoading}>
							Update Changes {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
						</button>
					) : (
						<button className="btn btn-color" onClick={handleSubmit} disabled={fLoading}>
							Submit {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
						</button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}
