import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory ,useLocation} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
	ApiDelete,
	ApiGet,
	ApiPost,
	ApiPut,
	ApiUpload,
} from "../../../helpers/API/ApiData";
import { ImSpinner9 } from 'react-icons/im'
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { MdCancel } from 'react-icons/md'
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import loader from '../../../media/loaderSkyline.gif'
import DateRangePicker from "react-bootstrap-daterangepicker";


export default function Alert() {
	const history = useHistory();
	const location = useLocation();

	const [data, setData] = useState([]);
	const [modal, setModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [totalpage, settotalpage] = useState(0);
	const [currentpage, setcurrentpage] = useState(1);
	const [pagesize, setpagesize] = useState(10);
	const [searching, setsearching] = useState("");
	const [error, setError] = useState({});
	const [flag, setFlag] = useState(false);
	const [fLoading, setFLoading] = useState(false);
	const [fetching, setFetching] = useState(false);
	const [showImage, setShowImage] = useState("");
	const [newData, setNewData] = useState({
		firstName: "",
		lastName: "",
		phoneNumber: "",
		whatsappNumber: "",
		email: "",
	});
	const [playStartDate, setPlayStartDate] = useState(null);
	const [playEndDate, setPlayEndDate] = useState(null);

	const columns = [
		{
			dataField: "old",
			text: "Old Complaint No.",
			sort: true,
			formatter: (cell, row) => {
				return <div onClick={() => history.push("/complaint-details/" + row?.oldComplaint_Data[0]?._id)}>{row?.oldComplaint_Data[0]?.complaintNo || "0"}</div>;
			},
		},
		{
			dataField: "Machin No",
			text: "Machin No",
			sort: true,
			formatter: (cell, row) => {
				return <div className="d-flex align-items-center" role="button">
					{/* <div className="symbol symbol-50px symbol-light mr-4">
						<img src={row?.image} className="img-fluid object-cover" loading="lazy" />
					</div> */}
					<div>
						<a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
							{row?.machine_Data[0]?.machineNo || "-"}
						</a>
					</div>
				</div>
			},
		},
		{
			dataField: "eng",
			text: "Engineer Name",
			sort: true,
			formatter: (cell, row) => {
				return <div>{row?.eng_Data[0]?.firstName}{" "}{row?.eng_Data[0]?.lastName}</div>;
			},
		},
		{
			dataField: "End Time",
			text: "Old Compliant Time",
			sort: true,
			formatter: (cell, row) => {
				return <div>{moment(row?.oldComplaint_Data[0]?.endTime).format("DD-MM-YYYY hh:mm:ss") || "-"}</div>;
			},
		},
		{
			dataField: "new",
			text: "New Complaint No.",
			sort: true,
			formatter: (cell, row) => {
				return <div onClick={() => history.push("/complaint-details/" + row?.newComplaint_Data[0]?._id)}>{row?.newComplaint_Data[0]?.complaintNo || "0"}</div>
			},
		},
		{
			dataField: "status",
			text: "status",
			sort: true,
			formatter: (cell, row) => {
			  if (row?.newComplaint_Data[0]?.status === 0) {
				return (
				  <span
					className="label font-weight-bold label-lg label-light-warning label-inline"
					role="button"
					// onClick={() => handleStatus(row)}
				  >
					Pending
				  </span>
				);
			  }
			  if (row?.newComplaint_Data[0]?.status === 1) {
				return (
				  <span
					className="label font-weight-bold label-lg label-light-success label-inline"
					role="button"
					// onClick={() => handleStatus(row)}
				  >
					Assigned
				  </span>
				);
			  }
			  if (row?.newComplaint_Data[0]?.status === 2) {
				return (
				  <span className="label font-weight-bold label-lg label-light-info label-inline">
					Running
				  </span>
				);
			  }
			  if (row?.newComplaint_Data[0]?.status === 3) {
				return (
				  <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">
					Completed
				  </span>
				);
			  }
			},
		  },
		{
			dataField: "eng",
			text: "Assign Engineer Name",
			sort: true,
			formatter: (cell, row) => {
				return <div>{row?.newComplaint_Data[0]?.eng_data[0]?.firstName}{" "}{row?.newComplaint_Data[0]?.eng_data[0]?.lastName}</div>;
			},
		},
		
		{
			dataField: "Start Time",
			text: "New Compliant Time",
			sort: true,
			formatter: (cell, row) => {
				return <div>{row?.newComplaint_Data[0]?.createdAt ? moment(row?.newComplaint_Data[0]?.createdAt).format("DD-MM-YYYY hh:mm:ss") : "-"}</div>;
			},
		},
		
		
		
		{
			dataField: "name",
			text: "Client Name",
			sort: true,
			formatter: (cell, row) => {
				return <div>{row?.client_data[0]?.firstName}{" "}{row?.client_data[0]?.lastName}</div>;
			},
		},
		// {
		// 	dataField: "createdAt",
		// 	text: "Created At",
		// 	sort: true,
		// 	formatter: (cell, row) => {
		// 		return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
		// 	},
		// },
		// {
		// 	dataField: "action",
		// 	text: "Action",
		// 	sort: true,
		// 	formatter: (cell, row) => {
		// 		return (
		// 			<div style={{ width: "80px" }}>
		// 				<a
		// 					title="Edit customer"
		// 					className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
		// 					onClick={() => editBtnClick(row)}
		// 				>
		// 					<span className="svg-icon svg-icon-md svg-icon-primary">
		// 						<SVG
		// 							src={toAbsoluteUrl(
		// 								"/media/svg/icons/Communication/Write.svg"
		// 							)}
		// 						/>
		// 					</span>
		// 				</a>

		// 				<a
		// 					title="Delete customer"
		// 					className="btn btn-icon btn-light btn-hover-danger btn-sm"
		// 					onClick={() => {
		// 						Swal.fire({
		// 							text: `Are you sure you want to Delete ?`,
		// 							icon: "warning",
		// 							showCancelButton: true,
		// 							showConfirmButton: true,
		// 							confirmButtonText: `Yes, Delete`,
		// 							confirmButtonColor: "#D72852",
		// 							cancelButtonColor: "transparent",
		// 							cancelButtonText: "No, Cancel",
		// 						}).then((res) => {
		// 							if (res.isConfirmed) {
		// 								onDelete(row?._id);
		// 							}
		// 						});
		// 					}}
		// 				>
		// 					<span className="svg-icon svg-icon-md svg-icon-danger">
		// 						<SVG
		// 							src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
		// 						/>
		// 					</span>
		// 				</a>
		// 			</div>
		// 		);
		// 	},
		// },
	];
	const validate = (values) => {
		const err = {};
		if (!values.firstName) {
			err.firstName = "First name is required";
		}
		if (!values.lastName) {
			err.lastName = "Last name is required";
		}
		// if (!values.email) {
		// 	err.email = "Email address is required";
		// }
		if (!values.phoneNumber) {
			err.phoneNumber = "Phone number is required";
		}
		if (!values.whatsappNumber) {
			err.whatsappNumber = "whatsapp number is required";
		}
		setError(err);
		if (Object.keys(err).length === 0) {
			return false;
		} else {
			return true;
		}
	};

	const handleChange = (e) => {
		const { value, name } = e.target;
		if (name === "firstName" || name === "lastName") {
			if (value && !value.match(/^[A-Za-z]+$/)) { return }
		}
		if (name === "phoneNumber" || name === "whatsappNumber") {
			if (value && !value.match(/^[0-9]+$/)) { return }
		}
		setNewData({ ...newData, [name]: value.trimStart() });
	};
	const changeImage = (e) => {
		if (!e.target.files[0]) { return; }
		let file = e.target.files[0];
		let fileURL = URL.createObjectURL(file);
		setShowImage(fileURL);
	}
	console.log("showImageEngineer", showImage);
	const editBtnClick = (row) => {
		setIsEdit(true);
		setError({});
		setModal(true);
		setShowImage(row?.image)
		setNewData({
			id: row?._id,
			firstName: row?.firstName,
			lastName: row?.lastName,
			phoneNumber: row?.phoneNumber,
			whatsappNumber: row?.whatsappNumber,
			email: row?.email,
		});
	};
	const onDelete = async (Id) => {
		await ApiDelete(`/Eng/${Id}`)
			.then(() => {
				Swal.fire({
					text: "Engineer Deleted Successfully!!!",
					icon: "success",
					confirmButtonText: "Ok, Got it!",
					confirmButtonColor: "#338DE6",
				});
				fetchData(currentpage, pagesize, searching,
					playStartDate,
					playEndDate);
			})
			.catch((err) => ErrorToast(err?.message));
	};
	const onUpdate = async () => {
		if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
		setFLoading(true)
		let body = {
			id: newData?.id,
			firstName: newData?.firstName.trim(),
			lastName: newData?.lastName.trim(),
			phoneNumber: newData?.phoneNumber,
			whatsappNumber: newData?.whatsappNumber,
			email: newData?.email || null,
		};
		if (document.getElementById("image").files[0]) {
			const newImage = await imageChange();
			body["image"] = newImage;
		}
		if (!showImage) {
			body["image"] = null;
		}
		await ApiPut("/Eng", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				fetchData(currentpage, pagesize, searching,
					playStartDate,
					playEndDate);
				setFLoading(false)
			})
			.catch((err) => { ErrorToast(err?.message); setFLoading(false) });
	};
	const handleSubmit = async () => {
		if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
		setFLoading(true)
		let body = {
			firstName: newData?.firstName.trim(),
			lastName: newData?.lastName.trim(),
			phoneNumber: newData?.phoneNumber,
			whatsappNumber: newData?.whatsappNumber,
			email: newData?.email || null,
		};
		if (document.getElementById("image").files[0]) {
			const newImage = await imageChange();
			body["image"] = newImage;
		}
		await ApiPost("/Eng", body)
			.then((res) => {
				SuccessToast(res?.data?.message);
				setModal(false);
				fetchData(currentpage, pagesize, searching,
					playStartDate,
					playEndDate);
				setFLoading(false)
			})
			.catch((err) => { ErrorToast(err?.message); setFLoading(false) });
	};
	const handleonchnagespagination = (e) => {
		setpagesize(e.target.value);
		setcurrentpage(1);
		fetchData(1, parseInt(e.target.value), searching,
		playStartDate,
		playEndDate);
	};
	const onPaginationChange = (e, i) => {
		setcurrentpage(i);
		fetchData(i, pagesize, searching,
			playStartDate,
			playEndDate);
	};
	const onApply = (event, picker) => {
		setcurrentpage(1);
		let sDate = new Date(
		  new Date(picker?.startDate?._d).setDate(
			new Date(picker?.startDate?._d).getDate() + 1
		  )
		);
		setPlayStartDate(sDate);
		setPlayEndDate(new Date(picker?.endDate?._d));
		fetchData(
		  currentpage,
		  pagesize,
		  searching,
		 
		  sDate,
		  new Date(picker?.endDate?._d)
		);
	  };
	  const onCancel = (event, picker) => {
		setcurrentpage(1);
		fetchData(
		  currentpage,
		  pagesize,
		  searching,
		  null,
		  null
		);
	  };
	const imageChange = async (e) => {
		let imageDom = document.getElementById("image")
		if (!imageDom.files[0]) { return; }
		let file = imageDom.files[0];
		let fileURL = URL.createObjectURL(file);
		file.fileURL = fileURL;
		console.log("fileasd", file);

		let formData = new FormData();
		formData.append("image", file);
		let returnImg = ""
		await ApiUpload("admin/image", formData)
			.then((res) => {
				// setNewData({ ...newData, image: res?.data?.data });
				returnImg = res?.data?.data;
			})
			.catch((err) => console.log("res_blob", err));
		return returnImg;
	};
	const handlesearch = (e) => {
		setcurrentpage(1);
		setsearching(e.target.value);
		fetchData(1, pagesize, e.target.value,
			playStartDate,
			playEndDate);
	};

	const fetchData = async (page, limit, search,startDate,endDate) => {
		let body = { page, limit: Number(limit), search ,startDate,endDate};
		setFetching(true)
		await ApiPost("/alert", body)
			.then((res) => {
				console.log("/getEngs", res?.data?.data);
				setData(res?.data?.data?.response);
				settotalpage(res?.data?.data?.state?.page_limit);
				setFetching(false)
			})
			.catch((err) => { ErrorToast(err?.message); setFetching(false) });
	};
	useEffect(() => {
		if (location.state?.today) {
			//   newStatus = location.state;
			//   setPlayStatus(location.state);
			  if(location.state?.today){
				setPlayStartDate(new Date(new Date().setHours(0,0,0,0)))
				setPlayEndDate(new Date(new Date().setHours(23,59,59,999)))
			  }
			}
		fetchData(currentpage, pagesize, searching,
			location.state?.today?new Date(new Date().setHours(0,0,0,0)) :playStartDate,
		  location.state?.today?new Date(new Date().setHours(23,59,59,999)) :playEndDate);
	}, []);
	// useEffect(() => {
	// 	console.log("location", location);
	// 	// let newStatus = playStatus;
	// 	if (location.state?.today) {
	// 	//   newStatus = location.state;
	// 	//   setPlayStatus(location.state);
	// 	  if(location.state?.today){
	// 		setPlayStartDate(new Date(new Date().setHours(0,0,0,0)))
	// 		setPlayEndDate(new Date(new Date().setHours(23,59,59,999)))
	// 	  }
	// 	}
	// 	fetchData(
	// 	  currentpage,
	// 	  pagesize,
	// 	  searching,
	// 	  location.state?.today?new Date(new Date().setHours(0,0,0,0)) :playStartDate,
	// 	  location.state?.today?new Date(new Date().setHours(23,59,59,999)) :playEndDate
	// 	);
	// 	// getNotAssignEngineer();
	// 	// getAllEngineer();
	// 	// getAllClient();
	// 	// getMachine();
	// 	// getIssueType();
	//   }, []);
	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">Alerts</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							Alert
						</span>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<div className="card card-custom">
					<div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
						<div className="card-title ">
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip id="button-tooltip-2">Search by Mchine No and Engineer Name</Tooltip>}
							>
								<div class="input-icon">
									<input
										type="text"
										class="form-control bg-light"
										name="searchText"
										placeholder="Search by..."
										value={searching}
										onChange={(e) => handlesearch(e)}
										id="kt_datatable_search_query"
									/>
									<span>
										<i class="flaticon2-search-1 text-muted"></i>
									</span>
								</div>
							</OverlayTrigger>
						</div>
						<div className="col-md-2 my-2 pe-0">
                  <DateRangePicker onApply={onApply} onCancel={onCancel}>
                    <input
                      type="text"
                      className="form-control"
                      autoUpdateInput={false}
                      locale={{
                        cancelLabel: "Clear",
                      }}
                    />
                  </DateRangePicker>
                </div>

						{/* <div className="card-toolbar">
							<button
								className="btn btn-color font-weight-bolder btn-sm"
								onClick={() => {
									setIsEdit(false);
									setFlag(false);
									setError({});
									setModal(true);
									setShowImage("")
									setNewData({
										firstName: "",
										lastName: "",
										address: "",
										phoneNumber: "",
										email: "",
										password: ""
									});
								}}
							>
								Add Engineer
							</button>
						</div> */}
					</div>
					<div className="card-body mb-5">
						<BootstrapTable
							wrapperClasses="table-responsive"
							headerWrapperClasses="border-0"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							data={data}
							columns={columns}
							noDataIndication={() => {
								if (fetching) {
									return <div className="text-center"><img src={loader} className="h-100px" alt="loadingLogo" /></div>
								} else {
									return <NoDataTable />
								}
							}}
						/>
						<div className="d-flex justify-content-between  pt-10">
							<div className="my-2">
								<Pagination
									count={totalpage}
									page={currentpage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div className="my-2">
								<div className="d-flex align-items-center pagination-drpdown">
									<select
										className="form-control pagination-drpdown1 dropdownPage"
										id="kt_datatable_search_status"
										onChange={(e) => handleonchnagespagination(e)}
										value={pagesize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered>
				<Modal.Header closeButton>
					<Modal.Title>{isEdit ? "Update" : "Add New"} Engineer</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<Form.Group className="col-md-6">
							<Form.Label>First Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter First Name"
								label="firstName"
								id="firstName"
								required
								name="firstName"
								maxLength="20"
								onChange={handleChange}
								value={newData?.firstName}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.firstName &&
								<span className="errorInput">
									{error["firstName"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-6">
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Last Name"
								label="lastName"
								id="lastName"
								required
								name="lastName"
								maxLength="20"
								onChange={handleChange}
								value={newData?.lastName}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.lastName &&
								<span className="errorInput">
									{error["lastName"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Email Address"
								id="email1"
								className="form-control"
								required
								name="email"
								onChange={handleChange}
								value={newData?.email}
								onBlur={() => { if (flag) { validate(newData) } }}
								autoComplete="off"
							/>
							{/* {error?.email &&
								<span className="errorInput">
									{error["email"]}
								</span>} */}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>Phone Number</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Phone Number"
								label="phoneNumber"
								id="phoneNumber"
								required
								name="phoneNumber"
								maxLength="15"
								onChange={handleChange}
								value={newData?.phoneNumber}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.phoneNumber &&
								<span className="errorInput">
									{error["phoneNumber"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label>whatsapp Number</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Whatsapp Number"
								label="whatsappNumber"
								id="whatsappNumber"
								required
								name="whatsappNumber"
								maxLength="15"
								onChange={handleChange}
								value={newData?.whatsappNumber}
								onBlur={() => { if (flag) { validate(newData) } }}
							/>
							{error?.whatsappNumber &&
								<span className="errorInput">
									{error["whatsappNumber"]}
								</span>}
						</Form.Group>
						<Form.Group className="col-md-12">
							<Form.Label className="w-100">Image</Form.Label>
							<input name="image" type="file" id="image" className="form-control" onChange={changeImage} hidden={showImage && !showImage.includes("1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg")} />
							{showImage && !showImage.includes("1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg") &&
								<div className="symbol symbol-75 position-relative mt-1 ml-5">
									<img src={showImage} className="img-fluid object-cover" />
									<span className="imageRemovoIconSpan"
										onClick={() => {
											setShowImage("");
											document.getElementById("image").value = null;
										}}>
										<MdCancel color="red" fontSize={20} />
									</span>
								</div>}
						</Form.Group>
					</div>
				</Modal.Body>
				<Modal.Footer>
					{isEdit ? (
						<button className="btn btn-color" onClick={onUpdate} disabled={fLoading}>
							Update Changes {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
						</button>
					) : (
						<button className="btn btn-color" onClick={handleSubmit} disabled={fLoading}>
							Submit {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
						</button>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
}
