import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import Geocode from 'react-geocode'
import Select from 'react-select';
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { ImSpinner9 } from 'react-icons/im'
import { MdCancel } from 'react-icons/md'
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { MdVerified } from 'react-icons/md';

import loader from '../../../media/loaderSkyline.gif'
import MapPicker from 'react-google-map-picker'

export default function Client() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [options, setOptions] = useState([]);
  const [searching, setsearching] = useState("");
  const [error, setError] = useState({});
  const [areaCodes, setAreaCodes] = useState([]);
  const [machineModal, setMachineModal] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [flag, setFlag] = useState(false);
  const [fLoading, setFLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showImage, setShowImage] = useState("");
  const [defaultLocation, setDefaultLocation] = useState({ lat: 21.1702, lng: 72.8311 });
  const [location, setLocation] = useState({ lat: 21.1702, lng: 72.8311 });
  const [zoom, setZoom] = useState(10);

  const [newData, setNewData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    whatsappNumber: "",
    email: "",
    area: "",
    city: "",
    country: "",
    refersName: "",
    areaCode: "",
    latitude: "",
    longitude: "",
    companyName: "",
    machineId: "",
  });

  const columns = [
    {
      dataField: "title",
      text: "Name",
      sort: true,
      formatter: (cell, row) => {
        console.log("rowwww", row);
        return <div className="d-flex align-items-center" role="button" onClick={() => history.push("/client-details/" + row?._id)}>
          <div className="symbol symbol-50px symbol-light mr-4">
            <img src={row?.image} className="img-fluid object-cover" loading="lazy" />
          </div>
          <div>
            <a className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
              {row?.firstName || !row?.firstName && !row?.lastName && "-"} {row?.lastName || ""}
            </a>
          </div>
        </div>
      },
    },
    {
      dataField: "email",
      text: "email",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "phoneNumber",
      text: "phone Number",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "areaCode",
      text: "area Code",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell[0]?.code || "-"}</div>;
      },
    },
    {
      dataField: "refersName",
      text: "Reference Name",
      sort: false,
      formatter: (cell, row) => {
        return <div>{cell || "-"}</div>;
      },
    },
    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell && moment(cell).format("DD-MM-YYYY HH:mm A")}</div>;
      },
    },
    {
      dataField: "totalMachine",
      text: "Machines",
      sort: true,
      formatter: (cell, row) => {
        return <div>{cell || "0"}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div style={{ width: "170px" }}>
            <a
              title="Edit customer"
              className="btn btn-icon btn-light btn-hover-primary btn-sm"
              onClick={() => editBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
            </a>

            <a
              title="Delete customer"
              className="btn btn-icon btn-light btn-hover-danger btn-sm mx-2"
              onClick={() => {
                Swal.fire({
                  text: `Are you sure you want to Delete ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes, Delete`,
                  confirmButtonColor: "#D72852",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No, Cancel",
                }).then((res) => {
                  if (res.isConfirmed) {
                    onDelete(row?._id);
                  }
                });
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                />
              </span>
            </a>
            <a
              title="add machine"
              className="btn btn-icon btn-light btn-hover-danger btn-sm mx-2"
              onClick={() => {
                getMachine(row?._id)
                setNewData({ rowId: row?._id, machineId: "", clientName: `${row?.firstName || ""} ${row?.lastName || ""}` })
                setMachineModal(true)
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Electric/Washer.svg")}
                />
              </span>
            </a>

            {row?.isLogin?<a title="login"
              className="btn btn-icon btn-light btn-hover-success btn-sm">
            <MdVerified color="green" size="30"/>
            </a>:null}
          </div>
        );
      },
    },
  ];
  const validate = (values) => {
    const err = {};
    if (!values.firstName) {
      err.firstName = "First name is required";
    }
    if (!values.lastName) {
      err.lastName = "Last name is required";
    }
    // if (!values.email) {
    //   err.email = "Email address is required";
    // }
    // if (!values.companyName) {
    //   err.companyName = "Company name is required";
    // }
    if (!values.address) {
      err.address = "Address is required";
    }
    // if (!values.pinCode) {
    //   err.pinCode = "Pin code is required";
    // }
    if (!values.areaCode) {
      err.areaCode = "Area code is required";
    }
    if (!values.phoneNumber) {
      err.phoneNumber = "Phone number is required";
    }
    if (!values.whatsappNumber) {
      err.whatsappNumber = "whatsapp number is required";
    }
    setError(err);
    if (Object.keys(err).length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleChangeMachine = (e) => {
    // setMachineDataValue(e?.value ?? "");
    setNewData({ ...newData, machineId: e?.value })
  }
  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "firstName" || name === "lastName") {
      if (value && !value.match(/^[A-Za-z]+$/)) { return }
    }
    if (name === "phoneNumber" || name === "whatsappNumber") {
      if (value && !value.match(/^[0-9]+$/)) { return }
    }
    setNewData({ ...newData, [name]: value.trimStart() });
  };
  console.log("newData", newData);
  function handleChangeLocation(lat, lng) {
    setLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...defaultLocation });
    setZoom(10);
  }
  const changeImage = (e) => {
    if (!e.target.files[0]) { return; }
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    setShowImage(fileURL);
  }
  console.log("showImageClient", showImage);

  const editBtnClick = (row) => {
    setError({})
    setIsEdit(true);
    setModal(true);
    setShowImage(row?.image)
    setNewData({
      id: row?._id,
      firstName: row?.firstName,
      lastName: row?.lastName,
      email: row?.email,
      companyName: row?.companyName,
      phoneNumber: row?.phoneNumber,
      whatsappNumber: row?.whatsappNumber,
      address: row?.address,
      refersName: row?.refersName,
      areaCode: row?.areaCode[0]?._id || ""
    });
    setDefaultLocation({ ...location, lat: row?.latitude, lng: row?.longitude })
  };
  const onDelete = async (Id) => {
    await ApiDelete(`/Client/${Id}`)
      .then(() => {
        Swal.fire({
          text: "Client Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
    setFLoading(true)
    // const longlat = await getLongLat(newData?.area, newData?.city, newData?.country, newData?.pinCode)
    let body = {
      id: newData?.id,
      firstName: newData?.firstName.trim(),
      lastName: newData?.lastName.trim(),
      phoneNumber: Number(newData?.phoneNumber),
      whatsappNumber: Number(newData?.whatsappNumber),
      email: newData?.email || null,
      companyName: newData?.companyName.trim(),
      address: newData?.address.trim(),
      refersName: newData?.refersName,
      areaCode: newData?.areaCode,
      latitude: location?.lat,
      longitude: location?.lng
    };
    if (document.getElementById("image").files[0]) {
      const newImage = await imageChange();
      body["image"] = newImage;
    }
    if (!showImage) {
      body["image"] = null;
    }
    await ApiPut("/Client", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        setFLoading(false)
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => { ErrorToast(err?.message); setFLoading(false) });
  };
  const handleSubmit = async () => {
    if (validate(newData)) { setFlag(true); return } else { setFlag(false) }
    setFLoading(true)
    // const longlat = await getLongLat(newData?.area, newData?.city, newData?.country, newData?.pinCode)

    let body = {
      firstName: newData?.firstName.trim(),
      lastName: newData?.lastName.trim(),
      phoneNumber: Number(newData?.phoneNumber),
      whatsappNumber: Number(newData?.whatsappNumber),
      email: newData?.email || null,
      companyName: newData?.companyName.trim(),
      address: newData?.address.trim(),
      areaCode: newData?.areaCode,
      refersName: newData?.refersName,
      latitude: location?.lat,
      longitude: location?.lng
    };
    if (document.getElementById("image").files[0]) {
      const newImage = await imageChange();
      body["image"] = newImage;
    }
    await ApiPost("/Client", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        setFLoading(false)
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => { ErrorToast(err?.message); setFLoading(false) });
  };
  const getLongLat = async (address) => {
    console.log("address", address);
    Geocode.setApiKey("AIzaSyArwXDD4KUNWZQh6oDtLAGdnKKD6c2LNPI")
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    let obj = {}
    await Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        obj.longitude = lng;
        obj.latitude = lat;
      },
      (error) => {
        console.error(error);
      }
    );
    if (obj) {
      setDefaultLocation({ lat: obj.latitude, lng: obj.longitude })
    } else {
      setDefaultLocation({ lat: 21.1702, lng: 72.8311 })
    }
    console.log("obj", obj);

    return obj;
  }
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const imageChange = async (e) => {
    let imageDom = document.getElementById("image")
    if (!imageDom.files[0]) { return; }
    let file = imageDom.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    let returnImg = ""
    await ApiUpload("admin/image", formData)
      .then((res) => {
        // setNewData({ ...newData, image: res?.data?.data });
        returnImg = res?.data?.data;
      })
      .catch((err) => console.log("res_blob", err));
    return returnImg;
  };
  const handlesearch = (e) => {
    setcurrentpage(1);
    setsearching(e.target.value);
    fetchData(1, pagesize, e.target.value);
  };
  const handleAddMachine = async () => {
    let body = {
      id: newData?.machineId,
      clientId: newData?.rowId
    }
    await ApiPut("/machine", body)
      .then(res => {
        SuccessToast("Machine added successfully!!!");
        setMachineModal(false);
        fetchData(currentpage, pagesize, searching);
      })
      .catch(err => ErrorToast(err?.message))
  }
  const getMachine = async (cId) => {
    await ApiGet("/machines")
      .then(async (res) => {
        setMachineData(res?.data?.data)
        const arr = []
        const a = await res?.data?.data?.map((v, i) => {
          arr.push({ value: v?._id, label: `${v?.serialNumber} - ${v?.machineNo}` })
        })
        setOptions(arr)
      })
      .catch(err => ErrorToast(err?.message))
  }
  const getAreaCode = async () => {
    await ApiGet("/areacode")
      .then(res => setAreaCodes(res?.data?.data))
      .catch(err => ErrorToast(err?.message))
  }
  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    setFetching(true)
    await ApiPost("/getClients", body)
      .then((res) => {
        console.log("/getClients", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
        setFetching(false)
      })
      .catch((err) => { ErrorToast(err?.message); setFetching(false) });
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
    getAreaCode();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Clients</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Client
            </span>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div className="card card-custom">
          <div className="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div className="card-title ">
              {/* <h3 className="card-label">Video Playlist</h3> */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">Search by Name, Email and Area Code</Tooltip>}
              >
                <div class="input-icon">
                  <input
                    type="text"
                    class="form-control bg-light"
                    name="searchText"
                    placeholder="Search by..."
                    value={searching}
                    onChange={(e) => handlesearch(e)}
                    id="kt_datatable_search_query"
                  />
                  <span>
                    <i class="flaticon2-search-1 text-muted"></i>
                  </span>
                </div>
              </OverlayTrigger>
            </div>

            <div className="card-toolbar">
              <button
                className="btn btn-color font-weight-bolder btn-sm"
                onClick={() => {
                  setIsEdit(false);
                  setFlag(false)
                  setError({})
                  setModal(true);
                  setShowImage("")
                  setNewData({
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    whatsappNumber: "",
                    email: "",
                    address: "",
                    refersName: "",
                    areaCode: "",
                    latitude: "",
                    longitude: "",
                    companyName: ""
                  });
                  setDefaultLocation({ lat: 21.1702, lng: 72.8311 })
                }}
              >
                Add Client
              </button>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              noDataIndication={() => {
                if (fetching) {
                  return <div className="text-center"><img src={loader} className="h-100px" alt="loadingLogo" /></div>
                } else {
                  return <NoDataTable />
                }
              }}
            />
            <div className="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div className="my-2">
                <div className="d-flex align-items-center pagination-drpdown">
                  <select
                    className="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? "Update" : "Add New"} Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row overflow-auto" style={{ maxHeight: "700px" }}>
            <Form.Group className="col-md-6">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                label="firstName"
                id="firstName"
                required
                name="firstName"
                maxLength="20"
                onChange={handleChange}
                value={newData?.firstName}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
              {error?.firstName &&
                <span className="errorInput">
                  {error["firstName"]}
                </span>}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                label="lastName"
                id="lastName"
                required
                name="lastName"
                maxLength="20"
                onChange={handleChange}
                value={newData?.lastName}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
              {error?.lastName &&
                <span className="errorInput">
                  {error["lastName"]}
                </span>}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Address"
                id="email1"
                className="form-control"
                required
                name="email"
                onChange={handleChange}
                value={newData?.email}
                onBlur={() => { if (flag) { validate(newData) } }}
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Company Name"
                className="form-control"
                name="companyName"
                maxLength="20"
                onChange={handleChange}
                value={newData?.companyName}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
              {error?.companyName &&
                <span className="errorInput">
                  {error["companyName"]}
                </span>}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Phone Number"
                label="phoneNumber"
                id="phoneNumber"
                required
                name="phoneNumber"
                maxLength="15"
                onChange={handleChange}
                value={newData?.phoneNumber}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
              {error?.phoneNumber &&
                <span className="errorInput">
                  {error["phoneNumber"]}
                </span>}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Whatsapp Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Whatsapp Number"
                label="whatsappNumber"
                id="whatsappNumber"
                required
                name="whatsappNumber"
                maxLength="15"
                onChange={handleChange}
                value={newData?.whatsappNumber}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
              {error?.whatsappNumber &&
                <span className="errorInput">
                  {error["whatsappNumber"]}
                </span>}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label className="w-100">Image</Form.Label>
              <input name="image" type="file" id="image" className="form-control" onChange={changeImage} hidden={showImage && !showImage.includes("1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg")} />
              {showImage && !showImage.includes("1000_F_64675209_7ve2XQANuzuHjMZXP3aIYIpsDKEbF5dD_jjxlsj.jpg") &&
                <div className="symbol symbol-75 position-relative mt-1 ml-5">
                  <img src={showImage} className="img-fluid object-cover" />
                  <span className="imageRemovoIconSpan"
                    onClick={() => {
                      setShowImage("");
                      document.getElementById("image").value = null;
                    }}>
                    <MdCancel color="red" fontSize={20} />
                  </span>
                </div>}
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Reference Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Reference Name"
                name="refersName"
                onChange={handleChange}
                value={newData?.refersName}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Area Code</Form.Label>
              <select
                className={`form-select ${!newData?.areaCode && "text-placeholder"} `}
                name="areaCode"
                onChange={handleChange}
                value={newData?.areaCode}
                role="button"
                onBlur={() => { if (flag) { validate(newData) } }}
              >
                <option value="" disabled>Select Area Code</option>
                {areaCodes?.map((v, i) => <option value={v?._id} key={v?._id}>{v?.code} - {v?.name}</option>)}
              </select>
              {error?.areaCode &&
                <span className="errorInput">
                  {error["areaCode"]}
                </span>}
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Full Address"
                label="address"
                id="address"
                required
                name="address"
                onChange={(e) => { handleChange(e); getLongLat(e.target.value) }}
                value={newData?.address}
                onBlur={() => { if (flag) { validate(newData) } }}
              />
              {error?.address &&
                <span className="errorInput">
                  {error["address"]}
                </span>}
            </Form.Group>
            <div className="rounded overflow-hidden mb-4">
              <MapPicker
                defaultLocation={defaultLocation}
                zoom={zoom}
                mapTypeId="roadmap"
                style={{ height: '200px' }}
                onChangeLocation={handleChangeLocation}
                onChangeZoom={handleChangeZoom}
                location={location}
                apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8' />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn-color" onClick={onUpdate} disabled={fLoading}>
              <span>Update Changes</span>
              {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
            </button>
          ) : (
            <button className="btn btn-color" onClick={handleSubmit} disabled={fLoading}>
              <span>Submit</span>
              {fLoading && <ImSpinner9 className="ml-3 icon-spin fs-18px" />}
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={machineModal} onHide={() => setMachineModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Machine to <span className="fw-boldest text-capitalize">{newData?.clientName}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <select className="form-select" name="machineId" value={newData?.machineId} onChange={handleChange}>
              <option value="" disabled>select machine</option>
              {machineData?.map((v, i) => <option key={v?._id} value={v?._id}>{v?.serialNumber} - {v?.machineNo}</option>)}
            </select> */}
            <Select
              className="basic-single"
              // defaultValue={isEdit && editOption}
              onChange={handleChangeMachine}
              placeholder="Select Machine"
              isClearable={true}
              isRtl={false}
              isSearchable={true}
              name="color"
              options={options}
              style={{
                control: (provided, state) => ({
                  ...provided,
                  border: state.isActive ? '2px solid red' : provided.border,
                }),
              }}
            />
            {error?.machineId && <span className="text-danger">{error["machineId"]}</span>}
          </div>
          <div className="text-end mt-5">
            <button className="btn btn-sm btn-color"
              onClick={() => {
                if (!newData?.machineId) {
                  setError({ machineId: "Machine is required!" });
                  return;
                } else {
                  setError({})
                }
                Swal.fire({
                  text: `Are you sure you want to add machine in ${newData?.clientName} ?`,
                  icon: "warning",
                  showCancelButton: true,
                  showConfirmButton: true,
                  confirmButtonText: `Yes`,
                  confirmButtonColor: "#0095e8",
                  cancelButtonColor: "transparent",
                  cancelButtonText: "No",
                }).then((res) => {
                  if (res.isConfirmed) {
                    handleAddMachine()
                  }
                });
              }}>Add</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
