import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import { ApiDelete, ApiPost, ApiPut } from "../../../helpers/API/ApiData";
import { ErrorToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import loader from "../../../media/loaderSkyline.gif";
import { Dropdown } from "react-bootstrap";

export default function TrashComplaint() {
	const history = useHistory();
	const [data, setData] = useState([]);
	const [totalpage, settotalpage] = useState(0);
	const [currentpage, setcurrentpage] = useState(1);
	const [pagesize, setpagesize] = useState(10);
	const [searching, setsearching] = useState("");
	const [fetching, setFetching] = useState(false);
	const [playDeletedBy, setPlayDeletedBy] = useState({ name: "Filter Delete By", number: null });


	const columns = [
		{
			dataField: "complaintNo",
			text: "complaint No",
			sort: true,
			formatter: (cell, row) => {
				console.log("rowwww", row);
				return <div>{cell || ""}</div>;
			},
		},
		{
			dataField: "client_data",
			text: "client name",
			sort: true,
			formatter: (cell, row) => {
				if (cell.length !== 0) {
					return <div>{cell[0]?.firstName || !cell[0]?.firstName && !cell[0]?.lastName && "N/A"} {cell[0]?.lastName || ""}</div>;
				} else {
					return <div>N/A</div>
				}
			},
		},
		{
			dataField: "machine_Data",
			text: "machine No",
			sort: true,
			formatter: (cell, row) => {
				if (cell.length !== 0) {
					return <div>{cell[0]?.machineNo || "-"}</div>;
				} else {
					return <div>N/A</div>;
				}
			},
		},
		{
			dataField: "machine_Data",
			text: "machine serial no",
			sort: true,
			formatter: (cell, row) => {
				if (cell.length !== 0) {
					return <div>{cell[0]?.serialNumber || "-"}</div>;
				} else {
					return <div>N/A</div>;
				}
			},
		},
		{
			dataField: "eng_Data",
			text: "engineer",
			sort: true,
			formatter: (cell, row) => {
				if (cell.length !== 0) {
					return <div>{cell[0]?.firstName || !cell[0]?.firstName && !cell[0]?.lastName && "N/A"} {cell[0]?.lastName || ""}</div>;
				} else {
					return <div>N/A</div>
				}
			},
		},
		{
			dataField: "status",
			text: "status",
			sort: true,
			formatter: (cell, row) => {
				if (cell === 0) {
					return <span className="label font-weight-bold label-lg label-light-warning label-inline">Pending</span>
				}
				if (cell === 1) {
					return <span className="label font-weight-bold label-lg label-light-success label-inline">Assigned</span>
				}
				if (cell === 2) {
					return <span className="label font-weight-bold label-lg label-light-info label-inline">Running</span>
				}
				if (cell === 3) {
					return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">Completed</span>
				}
			},
		},
		{
			dataField: "createdAt",
			text: "created at",
			sort: true,
			formatter: (cell, row) => {
				return <div>{moment(cell).format("DD-MM-YYYY")}</div>
			},
		},
		{
			dataField: "deletedBy",
			text: "deleted By",
			sort: true,
			formatter: (cell, row) => {
				if (cell === 0) {
					return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">Admin</span>
				} else if (cell === 1) {
					return <span className="label font-weight-bold label-lg label-light-info label-inline">Client</span>
				}
			},
		},
		{
			dataField: "action",
			text: "Action",
			sort: true,
			headerStyle: { width: "100px" },
			formatter: (cell, row) => {
				return (
					<>
						<a
							title="Delete"
							className="btn btn-icon btn-light btn-hover-danger btn-sm mx-2"
							onClick={() => {
								Swal.fire({
									text: `Are you sure you want to permanently Delete ?`,
									icon: "warning",
									showCancelButton: true,
									showConfirmButton: true,
									confirmButtonText: `Yes, Delete`,
									confirmButtonColor: "#D72852",
									cancelButtonColor: "transparent",
									cancelButtonText: "No, Cancel",
								}).then((res) => {
									if (res.isConfirmed) {
										onDelete(row?._id);
									}
								});
							}}
						>
							<span className="svg-icon svg-icon-md svg-icon-danger">
								<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} title="Delete" />
							</span>
						</a>
					</>
				);
			},
		},
	];
	const handleOnChangeStatus = (e) => {
		let a = JSON.parse(e)
		setPlayDeletedBy(a);
		fetchData(
			currentpage,
			pagesize,
			searching,
			a?.number
		);
	};
	const onDelete = async (Id) => {
		await ApiDelete("/trashComplaints/" + Id)
			.then(() => {
				Swal.fire({
					text: "Client Deleted Successfully!!!",
					icon: "success",
					confirmButtonText: "Ok, Got it!",
					confirmButtonColor: "#338DE6",
				});
				fetchData(currentpage, pagesize, searching, playDeletedBy?.number);
			})
			.catch((err) => ErrorToast(err?.message));
	};
	const handleonchnagespagination = (e) => {
		setpagesize(e.target.value);
		setcurrentpage(1);
		fetchData(1, parseInt(e.target.value), searching, playDeletedBy?.number);
	};
	const onPaginationChange = (e, i) => {
		setcurrentpage(i);
		fetchData(i, pagesize, searching);
	};
	const handlesearch = (e) => {
		setcurrentpage(1);
		setsearching(e.target.value);
		fetchData(1, pagesize, e.target.value, playDeletedBy?.number);
	};

	const fetchData = async (page, limit, search, deletedBy) => {
		let body = { page, limit, search, deletedBy };
		setFetching(true);
		await ApiPost("/trashComplaint", body)
			.then((res) => {
				console.log("/trashComplaint", res?.data?.data);
				setData(res?.data?.data?.response);
				settotalpage(res?.data?.data?.state?.page_limit);
				setFetching(false);
			})
			.catch((err) => {
				ErrorToast(err?.message);
				setFetching(false);
			});
	};
	useEffect(() => {
		fetchData(currentpage, pagesize, searching, playDeletedBy?.number);
	}, []);
	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<div className="title">
					<div className="fs-20px fw-bolder">Complaint Trash</div>
					<div>
						<span
							role="button"
							onClick={() => history.push("/dashboard")}
							className="text-hover-info text-muted"
						>
							Home
						</span>{" "}
						-{" "}
						<span className="text-muted" role="button">
							{" "}
							Trash Complaint
						</span>
					</div>
				</div>
			</div>
			<div className="d-flex flex-column flex-column-fluid h-100" id="kt_content">
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title w-100">
							<div class="row align-items-center w-100">
								<div className="col-md-3 my-2">
									<div class="input-icon">
										<input
											type="text"
											class="form-control bg-light"
											name="searchText"
											placeholder="Search by Name"
											value={searching}
											onChange={(e) => handlesearch(e)}
											id="kt_datatable_search_query"
										/>
										<span>
											<i class="flaticon2-search-1 text-muted"></i>
										</span>
									</div>
								</div>
								<div className="col-md-3 my-2">
									<Dropdown
										onSelect={handleOnChangeStatus}
									>
										<Dropdown.Toggle
											id="dropdown-basic"
											className="text-capitalize"
										>
											{playDeletedBy?.name}
										</Dropdown.Toggle>
										<Dropdown.Menu style={{ minWidth: "100%" }}>
											<Dropdown.Item eventKey={JSON.stringify({ name: "Filter Delete By", number: null })}>
												None of these
											</Dropdown.Item>
											<Dropdown.Item eventKey={JSON.stringify({ name: "By Admin", number: 0 })}>
												Admin
											</Dropdown.Item>
											<Dropdown.Item eventKey={JSON.stringify({ name: "By Client", number: 1 })}>
												Client
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
					<div className="card-body mb-5">
						<BootstrapTable
							wrapperClasses="table-responsive"
							headerWrapperClasses="border-0"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							keyField="_id"
							data={data}
							columns={columns}
							noDataIndication={() => {
								if (fetching) {
									return (
										<div className="text-center">
											<img src={loader} className="h-100px" alt="loadingLogo" />
										</div>
									);
								} else {
									return <NoDataTable />;
								}
							}}
						/>
						<div className="d-flex justify-content-between  pt-10">
							<div className="my-2">
								<Pagination
									count={totalpage}
									page={currentpage}
									onChange={onPaginationChange}
									variant="outlined"
									shape="rounded"
									className="pagination_"
								/>
							</div>
							<div className="my-2">
								<div className="d-flex align-items-center pagination-drpdown">
									<select
										className="form-control pagination-drpdown1 dropdownPage"
										id="kt_datatable_search_status"
										onChange={(e) => handleonchnagespagination(e)}
										value={pagesize}
									>
										<option value={10}>10</option>
										<option value={20}>20</option>
										<option value={30}>30</option>
										<option value={50}>50</option>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
