import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useParams } from "react-router-dom";
import NoDataTable from "../../../common/noDataTable";
import loader from "../../../media/loaderSkyline.gif";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import { ApiPost } from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import Pagination from "@material-ui/lab/Pagination";

export default function ClientMachines({ data }) {
	const history = useHistory()
	const { id } = useParams();
	 const [fetching, setFetching] = useState(false);
	 const [totalpage, settotalpage] = useState(0);
	const [currentpage, setcurrentpage] = useState(1);
	const [pagesize, setpagesize] = useState(10);
	const [machineData, setMachineData] = useState([]);

	const machineColumns = [
		{
			dataField: "image",
			text: "image",
			sort: true,
			formatter: (cell, row) => {
				return (
					<div className="symbol symbol-50px symbol-light mr-4">
						<img src={cell[0]} className="img-fluid object-cover" loading="lazy" />
					</div>
				);
			},
		},
		{
			dataField: "serialNumber",
			text: "serial Number",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		{
			dataField: "machineNo",
			text: "machine no",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell || "-"}</div>;
			},
		},
		// {
		// 	dataField: "machineName",
		// 	text: "machine Name",
		// 	sort: true,
		// 	formatter: (cell, row) => {
		// 		return <div>{cell || "-"}</div>;
		// 	},
		// },
		{
			dataField: "buyDate",
			text: "Buy Date",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell && moment(cell).format("DD-MM-YYYY")}</div>;
			},
		},
		{
			dataField: "expDate",
			text: "warranty Date",
			sort: true,
			formatter: (cell, row) => {
				return <div>{cell && moment(cell).format("DD-MM-YYYY")}</div>;
			},
		},
		{
			dataField: "machineStatus",
			text: "status",
			sort: true,
			formatter: (cell, row) => {
				if (cell === 1) {
					return <span className="label font-weight-bold label-lg bg-light-success text-success label-inline">Warranty</span>
				}
				else if (cell === 2) {
					return <span className="label font-weight-bold label-lg label-light-info label-inline">AMC</span>
				} else if (cell === 3) {
					return <span className="label font-weight-bold label-lg label-light-danger label-inline">Expired</span>
				}
			},
		},
		{
			dataField: "view",
			text: "view",
			sort: true,
			formatter: (cell, row) => {
				return (<>
					<a
						title="View User"
						className="btn btn-icon btn-light btn-hover-primary btn-sm"
						onClick={() => history.push("/machine-details/" + row?._id)}
					>
						<span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
							<AiFillEye className="" />
						</span>
					</a>
				</>)
			}
		}
	];
	

	const fetchMachineData = async () => {
		setFetching(true);
		let body = {
			clientId: id,
			page: currentpage,
			limit: Number(pagesize)
		}
		await ApiPost("/machines", body)
			.then((res) => {
				console.log("machines/Id", res?.data?.data?.response);
				setMachineData(res?.data?.data?.response);
				settotalpage(res?.data?.data?.state?.page_limit);
				setFetching(false);
			})
			.catch((err) => {
				ErrorToast(err?.message);
				setFetching(false);
			});
	};
	useEffect(() => {
		fetchMachineData();
	}, [pagesize,currentpage]);

	const handleonchnagespagination = (e) => {
		setpagesize(e.target.value);
		setcurrentpage(1);
		// fetchMachineData(1, parseInt(e.target.value));
	};
	const onPaginationChange = (e, i) => {
		setcurrentpage(i);
		// fetchMachineData(i, pagesize);
	};
	return <>
		<BootstrapTable
			wrapperClasses="table-responsive"
			headerWrapperClasses="border-0"
			bordered={false}
			classes="table table-head-custom table-vertical-center overflow-hidden"
			bootstrap4
			keyField="_id"
			data={machineData || []}
			columns={machineColumns}
			noDataIndication={() => {
				if (fetching) {
					return (
						<div className="text-center">
							<img src={loader} className="h-100px" alt="loadingLogo" />
						</div>
					);
				} else {
					return <NoDataTable />;
				}
			}}
		/>
		  <div className="d-flex justify-content-between  pt-10">
                <div className="my-2">
                  <Pagination
                    count={totalpage}
                    page={currentpage}
                    onChange={onPaginationChange}
                    variant="outlined"
                    shape="rounded"
                    className="pagination_"
                  />
                </div>
                <div className="my-2">
                  <div className="d-flex align-items-center pagination-drpdown">
                    <select
                      className="form-control pagination-drpdown1 dropdownPage"
                      id="kt_datatable_search_status"
                      onChange={(e) => handleonchnagespagination(e)}
                      value={pagesize}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={50}>50</option>
                    </select>
                  </div>
                </div>
              </div>
	</>;
}
