import React, { useEffect, useState } from "react";
import GoogleMapReact from 'google-map-react';
import { ApiGet } from '../../../helpers/API/ApiData'
import marker from '../../../media/marker.svg'
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ErrorToast } from "../../../helpers/Toast"


function Map() {
  const history = useHistory();
  const [allEngDir, setAllEngDir] = useState([]);
  const [dropEng, setDropEng] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);


  const defaultProps = {
    center: {
      lat: 21.1702,
      lng: 72.8311
    },
    zoom: 13
  };
  const getAllEngDirections = async () => {
    await ApiGet("/dir")
      .then(res => {
        setAllEngDir(res?.data?.data);
        setDropEng(res?.data?.data);
      })
      .catch(err => ErrorToast(err?.message));
  }

  useEffect(() => {
    getAllEngDirections();
  }, []);

  return <>
    <div className="row mb-3">
      <div className="col-md-3">
        <select name="" className="form-select" id=""
          onChange={async (e) => {
            if (e.target.value) {
              await ApiGet("/dir/" + e.target.value)
                .then(res => { setAllEngDir(res?.data?.data) })
                .catch(err => ErrorToast(err?.message))
            } else {
              await getAllEngDirections();
            }
          }}
        >
          <option value="">Select Engineer</option>
          {dropEng?.map((v, index) => {
            return <option value={v?.eng_Data[0]?._id}>{v?.eng_Data[0]?.firstName} {v?.eng_Data[0]?.lastName}</option>
          })}
        </select>
      </div>
    </div>
    <div style={{ height: '100%', width: '100%', borderRadius: "8px", overflow: "hidden" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCHjaCHFzH5ZTyMC26Fx1I0UMi8OTeNNd4' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
      >
        {allEngDir?.length > 0 && allEngDir?.map((v, index) => {
          console.log("v?.latitude", v, index);
          // return (<>
          //   <span lat={v?.latitude} lng={v?.longitude}>
          //     <OverlayTrigger
          //       trigger="click"
          //       key={i}
          //       placement="top"
          //       lat={v?.latitude}
          //       lng={v?.longitude}
          //       className="position-relative"
          //       overlay={
          //         <Popover id="popover-positioned-top">
          //           <Popover.Header as="h3">popover top</Popover.Header>
          //           <Popover.Body>
          //             you are smarter than your thinking
          //           </Popover.Body>
          //         </Popover>
          //       }
          //     >
          //       <img role="button" src={marker || ""} style={{ height: "40px", width: "30px" }} />
          //     </OverlayTrigger>
          //   </span>
          // </>)
          return (
            // <OverlayTrigger
            //   trigger="click"
            //   key={i}
            //   lat={v?.latitude}
            //   lng={v?.longitude}
            //   placement="top"
            //   className="position-relative"
            //   overlay={
            //     <Popover id="popover-positioned-top">
            //       <Popover.Header as="h3">popover top</Popover.Header>
            //       <Popover.Body>
            //         you are smarter than your thinking
            //       </Popover.Body>
            //     </Popover>
            //   }
            // >
            <span
              lat={v?.latitude}
              lng={v?.longitude}
              key={"marker" + index}
              className="position-relative"
              style={{ cursor: "context-menu" }}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              {hoveredItem === index &&
                <div className="custom-popup text-center">
                  <h3 className="border-bottom border-secondary pb-3">{v?.eng_Data[0]?.firstName || "-"} {v?.eng_Data[0]?.lastName || "-"}</h3>
                  <span className="fs-5 me-3">Status : </span>
                  {v?.eng_Data[0]?.isRunning ?
                    <span className="label font-weight-bold label-lg label-light-danger label-inline px-5" role="button" onClick={() => history.push("/complaint-details/" + v?.complaint_Data[0]?._id)}>Busy</span>
                    : <span className="label font-weight-bold label-lg bg-light-success text-success label-inline px-5">Free</span>}
                </div>
              }
              <img
                role="button"
                src={v?.eng_Data[0]?.image || marker}
                style={{ height: "40px", width: "40px", borderRadius: "50px", border: "2px solid white", objectFit: "cover" }}
              />
            </span>
            // </OverlayTrigger>
          );

        })}
      </GoogleMapReact>
    </div>
  </>;
}

export default Map;
